import { AlertIcon, Group, Td, Text } from '@shared/components'
import { dayjs } from '@shared/utils'
import { SmartPhrase } from '../../../employees/MEmployeesSmartPhrasesTab'
import { SmartPhraseActions } from './SmartPhraseActions'
import { SmartPhraseActionVariation } from './SmartPhraseTable'

type SmartPhraseRowProps = {
  smartPhrase: SmartPhrase
  isCopied: boolean
  onCopySmartPhrase: () => void
  variation: SmartPhraseActionVariation
}

export const SmartPhraseRow = ({
  smartPhrase,
  isCopied,
  onCopySmartPhrase,
  variation,
}: SmartPhraseRowProps) => {
  return (
    <tr key={smartPhrase.oid} onClick={() => onCopySmartPhrase()}>
      <Td>
        <Text
          bold
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {smartPhrase.name}
        </Text>
      </Td>
      <Td>
        {smartPhrase.categoryName ? (
          <Text
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
            bold
          >
            {smartPhrase.categoryName}
          </Text>
        ) : (
          <Group spacing='xs'>
            <AlertIcon color={colors => colors.text[1]} />
            <Text color={colors => colors.text[1]}>No category</Text>
          </Group>
        )}
      </Td>
      <Td>
        <Text
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {dayjs(smartPhrase.updatedAt).fromNow()}
        </Text>
      </Td>
      <Td>
        <Group position='apart' noWrap>
          <Text
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {smartPhrase.text}
          </Text>
          <SmartPhraseActions
            smartPhrase={smartPhrase}
            isCopied={isCopied}
            onCopySmartPhrase={onCopySmartPhrase}
            variation={variation}
          />
        </Group>
      </Td>
    </tr>
  )
}
