import { toTime } from '@shared/utils'
import { useQuery } from 'react-query'
import { actionItemsApi, notificationsApi } from '../../api'

export const useAlertsCount = ({
  employeeId = '',
}: {
  employeeId: string
}): [boolean, number, number, number, () => void] => {
  const actionItemsCount = useQuery(
    ['actionItemsApi.getOpenActionItemsCount', employeeId],
    () => {
      return actionItemsApi.getOpenActionItemsCount({ employeeId })
    },
    { enabled: Boolean(employeeId) },
  )

  const notificationsCount = useQuery(
    ['notificationsApi.getUnreadNotificationsCount', employeeId],
    () => notificationsApi.getUnreadNotificationsCount({ employeeId }),
    {
      enabled: Boolean(employeeId),
      refetchOnWindowFocus: true,
      staleTime: toTime('30 sec').ms(),
    },
  )

  const refresh = () => {
    void actionItemsCount.refetch()
    void notificationsCount.refetch()
  }

  return [
    actionItemsCount.isLoading || notificationsCount.isLoading,
    actionItemsCount.data || 0,
    notificationsCount.data || 0,
    (actionItemsCount.data || 0) + (notificationsCount.data || 0),
    refresh,
  ]
}
