import { useDisclosure } from '@mantine/hooks'
import {
  ArchiveIcon,
  CalendarIcon,
  Center,
  CheckIcon,
  EM_DASH,
  Group,
  Menu,
  Modal,
  MoreVerticalIcon,
  Pill,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Table,
  Td,
  TertiaryButton,
  Text,
  Th,
  Tooltip,
  UnstyledButton,
  showNotification,
} from '@shared/components'
import { ISOString } from '@shared/types'
import { dayjs, template, toTime } from '@shared/utils'
import range from 'lodash/range'
import uniqBy from 'lodash/uniqBy'
import { useQueryClient } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'
import LoadingRow from '../../components/atoms/LoadingRow'
import { useInvalidateLunaQuery, useLunaMutation, useLunaQuery } from '../../utils/hooks'
import { DropInClinic } from './ClinicPage'

const EmptyTable = () => (
  <tr className='mantine'>
    <td colSpan={Object.keys(COLS).length} align='center'>
      <Stack
        align='center'
        justify='center'
        py='md'
        sx={({ other: { colors } }) => ({
          backgroundColor: colors.background[1],
          flex: 1,
          alignSelf: 'stretch',
        })}
      >
        <Pill status='none' variant='filled'>
          No drop-in clinic attendees found
        </Pill>
      </Stack>
    </td>
  </tr>
)

const COLS = {
  rowNumber: '',
  patientName: 'Patient Name',
  status: 'Status',
  nextVisit: 'Next Visit',
  action: 'Action',
}

const TableHeader = () => {
  return (
    <thead className='mantine'>
      <tr className='mantine'>
        <Th
          sortable={false}
          style={{
            width: '1ch',
          }}
        >
          {COLS.rowNumber}
        </Th>
        <Th sortable={false}>{COLS.patientName}</Th>
        <Th sortable={false}>{COLS.status}</Th>
        <Th sortable={false}>{COLS.nextVisit}</Th>
        <Th
          sortable={false}
          style={{
            width: '6ch',
          }}
        >
          {COLS.action}
        </Th>
      </tr>
    </thead>
  )
}

const RemoveFromDropInClinicModal = ({
  opened,
  onClose,
  patientId,
  clinicianFirstName,
  datetimeStart,
  datetimeEnd,
  clinicId,
}: {
  opened: boolean
  onClose: () => void
  patientId: string
  clinicianFirstName: string
  datetimeStart: string
  datetimeEnd: string
  clinicId: string
}) => {
  const queryClient = useQueryClient()
  const clinicDateString = `${dayjs(datetimeStart).format('dddd')} between ${dayjs(
    datetimeStart,
  ).format('h')} - ${dayjs(datetimeEnd).format('h A')}.`

  const removeFromClinicMutation = useLunaMutation(
    'DELETE /drop-in-clinics/:clinicId/patients/:patientId',
  )

  const removeButtonOnClick = () => {
    removeFromClinicMutation.mutate(
      {
        params: {
          clinicId,
          patientId,
        },
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries('GET /drop-in-clinics/:clinicId/patients')
          onClose()
        },
      },
    )
  }

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title='Remove patient from drop-in clinic'
      footer={
        <Group position='right' noWrap>
          <SecondaryButton
            onClick={onClose}
            disabled={removeFromClinicMutation.isLoading}
          >{`No, don't remove`}</SecondaryButton>
          <PrimaryButton
            onClick={removeButtonOnClick}
            disabled={removeFromClinicMutation.isLoading}
            loading={removeFromClinicMutation.isLoading}
          >
            Yes, remove patient from clinic
          </PrimaryButton>
        </Group>
      }
    >
      <Stack spacing='lg'>
        <Text>
          {`Patient was added to ${clinicianFirstName}'s drop-in clinic on `}
          <Text bold component='span'>
            {clinicDateString}
          </Text>
        </Text>
        <Text>Would you like to remove this patient from the clinic?</Text>
      </Stack>
    </Modal>
  )
}

const ClinicActionMenu = (props: {
  patientId: string
  enrollmentId: string
  clinicId: string
  datetimeStart: ISOString
  datetimeEnd: ISOString
  clinicianFirstName: string
  confirmedAt: ISOString | null
  attendedAt: ISOString | null
}) => {
  const invalidateQuery = useInvalidateLunaQuery()
  const [menuOpen, menuHandlers] = useDisclosure()
  const [removeModalOpen, removeModalHandlers] = useDisclosure()
  const navigate = useNavigate()

  const createVisit = useLunaMutation('POST /drop-in-clinics/:clinicId/patients/:patientId/visit', {
    onSuccess: ({ data }) => {
      // Refresh the clinic attendees list so that patient goes from confirmed -> attended
      void invalidateQuery('GET /drop-in-clinics/:clinicId/patients')
      showNotification({
        variant: 'success',
        title: 'Visit successfully created',
        autoClose: toTime('30 seconds').ms(),
        withCloseButton: true,
        message: (
          <Group position='right'>
            <TertiaryButton
              size='sm'
              leftIcon={<CalendarIcon />}
              onClick={() => {
                navigate(`/patients/${props.patientId}/notes/${data.visitId}`)
              }}
            >
              Visit note
            </TertiaryButton>
          </Group>
        ),
      })
    },
  })

  // Show create visit action if patient is confirmed but not attended
  const showCreateVisitAction = props.confirmedAt && !props.attendedAt

  return (
    <>
      <RemoveFromDropInClinicModal
        opened={removeModalOpen}
        onClose={removeModalHandlers.close}
        {...props}
      />
      <Menu position='bottom-end' opened={menuOpen} onClose={menuHandlers.close}>
        <Center>
          <Menu.Target>
            <SecondaryButton
              size='xs'
              onClick={menuHandlers.open}
              leftIcon={<MoreVerticalIcon />}
            />
          </Menu.Target>
        </Center>
        <Menu.Dropdown>
          <Menu.Item icon={<ArchiveIcon />} onClick={removeModalHandlers.open}>
            Remove from clinic
          </Menu.Item>
          <Menu.Item
            disabled={createVisit.isLoading || !showCreateVisitAction}
            icon={<CalendarIcon />}
            onClick={() => {
              createVisit.mutate({
                params: {
                  clinicId: props.clinicId,
                  patientId: props.patientId,
                },
                data: {
                  enrollmentId: props.enrollmentId,
                },
              })
            }}
          >
            Create visit
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  )
}

const UnconfirmedActionMenu = ({
  patientName,
  enrollmentId,
}: {
  enrollmentId: string
  patientName: string
}) => {
  const [menuOpen, menuHandlers] = useDisclosure()
  const invalidateQuery = useInvalidateLunaQuery()
  const confirmPatient = useLunaMutation('POST /drop-in-clinics/confirm', {
    onSuccess: () => {
      void invalidateQuery('GET /drop-in-clinics/:clinicId/patients')
    },
  })

  return (
    <Menu position='bottom' opened={menuOpen} onClose={menuHandlers.close}>
      <Menu.Target>
        <UnstyledButton onClick={menuHandlers.open}>
          <Pill variant='filled' status='warning'>
            Unconfirmed
          </Pill>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          icon={<CheckIcon />}
          onClick={() => {
            confirmPatient.mutate({
              data: {
                enrollmentId,
              },
            })
          }}
        >
          <Text size='xs'>Confirm {patientName}</Text>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

const getConfirmedAtLabel = (datetime: ISOString) => {
  return template('Confirmed at {time} on {date} ET', {
    time: dayjs(datetime).tz('America/New_York').format('h:mma'),
    date: dayjs(datetime).tz('America/New_York').format('MM/DD'),
  })
}

export const DropInClinicTable = (props: { clinic: DropInClinic | null }) => {
  const clinicEnrollmentsQuery = useLunaQuery(
    'GET /drop-in-clinics/:clinicId/patients',
    {
      params: {
        clinicId: props.clinic?.oid || '',
      },
    },
    {
      enabled: Boolean(props.clinic?.oid),
    },
  )

  const patients = clinicEnrollmentsQuery.data?.data || []

  const unconfirmedPatients = patients.filter(d => !d.confirmedAt)
  const confirmedPatients = patients.filter(d => Boolean(d.confirmedAt) && !d.attendedAt)
  const attendedPatients = patients.filter(d => Boolean(d.attendedAt))

  const orderedPatients = uniqBy(
    [...attendedPatients, ...confirmedPatients, ...unconfirmedPatients],
    d => d.patientId,
  )

  const { clinic } = props

  if (!clinic || clinicEnrollmentsQuery.isLoading) {
    return (
      <Stack p='lg'>
        <Table withBorder striped>
          <TableHeader />
          <tbody className='mantine'>
            {range(0, 4).map(i => (
              <LoadingRow key={i} headersLength={Object.keys(COLS).length} />
            ))}
          </tbody>
        </Table>
      </Stack>
    )
  }

  return (
    <Stack p='lg'>
      <Table withBorder striped>
        <TableHeader />
        <tbody className='mantine'>
          {(orderedPatients || []).map((entry, idx) => (
            <tr key={entry.patientId} className='mantine'>
              <Td>
                <Text size='xs' color={c => c.text[1]}>
                  {idx + 1}
                </Text>
              </Td>
              <Td>
                <TertiaryButton component={Link} to={`/patients/${entry.patientId}`}>
                  {entry.patientName}
                </TertiaryButton>
              </Td>
              <Td>
                {/* ATTENDED */}
                {Boolean(entry.confirmedAt) && Boolean(entry.attendedAt) && (
                  <Pill variant='filled' status='success'>
                    Attended
                  </Pill>
                )}
                {/* CONFIRMED */}
                {entry.confirmedAt && !entry.attendedAt && (
                  <Tooltip position='top' label={getConfirmedAtLabel(entry.confirmedAt)}>
                    <Pill variant='filled' status='success'>
                      Confirmed
                    </Pill>
                  </Tooltip>
                )}
                {/* UNCONFIRMED */}
                {!entry.confirmedAt && !entry.attendedAt && (
                  <UnconfirmedActionMenu
                    enrollmentId={clinic.oid}
                    patientName={entry.patientName}
                  />
                )}
              </Td>
              <Td>
                {entry.nextFollowUpDate ? (
                  dayjs(entry.nextFollowUpDate).format('MM/DD')
                ) : (
                  <Text size='xs' color={c => c.text[1]}>
                    {EM_DASH}
                  </Text>
                )}
              </Td>
              <Td>
                <ClinicActionMenu
                  enrollmentId={entry.enrollmentId}
                  patientId={entry.patientId}
                  clinicId={clinic.oid}
                  clinicianFirstName={clinic.clinicianFirstName}
                  datetimeStart={clinic.datetimeStart}
                  datetimeEnd={clinic.datetimeEnd}
                  confirmedAt={entry.confirmedAt || null}
                  attendedAt={entry.attendedAt || null}
                />
              </Td>
            </tr>
          ))}
          {clinic.oid && !orderedPatients?.length && !clinicEnrollmentsQuery.isLoading && (
            <EmptyTable />
          )}
        </tbody>
      </Table>
    </Stack>
  )
}
