import { Box, Center, SecondaryButton, Stack, Text, emojiMap } from '@shared/components'
import { Appointment, Patient } from '@shared/types'
import { useState } from 'react'
import { useEmrQuery, useLunaMutation } from '../../../../utils/hooks'
import { VisitNoteSection } from '../VisitNoteSection'
import { PatientVisitNoteLoader } from './visitNoteForm/PatientVisitNoteLoader'

type VisitNoteTextSummarizationProps = {
  currentAppointment: Appointment
  patient: Patient
}

export const VisitNoteTextSummarization = ({
  currentAppointment,
  patient,
}: VisitNoteTextSummarizationProps) => {
  const [textSummary, setTextSummary] = useState<string>('')

  const appointmentsQuery = useEmrQuery(
    'GET /patient/:patientId/appointments',
    {
      params: { patientId: patient.oid },
    },
    { enabled: Boolean(patient?.oid) },
  )
  const appointments = appointmentsQuery.data
  const textSummarizationMutation = useLunaMutation('POST /text-summarization')

  if (!appointments) {
    return null
  }
  const currentAppointmentIndex = appointments.findIndex(i => i.oid === currentAppointment.oid)

  const visits = appointments
    /*
     * Remove everything before the current appointment, as it is not relevant.
     */
    .slice(currentAppointmentIndex)
    // Remove no show appointments
    .filter(appt => {
      return !appt?.noShow && !appt?.canceled
    })
    .map(visit => {
      return {
        oid: visit.oid,
        datetime: visit.datetime,
        type: visit.type,
        employeeId: visit.employeeId,
        canceled: visit.canceled,
        noShow: visit.noShow,
      }
    })

  const previousAppointment = visits.at(-1)

  const startDate = previousAppointment?.datetime
  const endDate = currentAppointment?.datetime

  if (!startDate || !endDate) {
    return null
  }

  const generateTextSummary = () => {
    textSummarizationMutation.mutate(
      {
        data: {
          patientId: patient?.oid,
          startDate,
          endDate,
        },
      },

      {
        onSuccess: data => {
          const aiSummary = data.data.summary
          setTextSummary(aiSummary || 'Unable to generate')
        },
      },
    )
  }

  return (
    <VisitNoteSection title='Recent tasks summary'>
      <Stack spacing='sm'>
        <Center>
          <SecondaryButton
            loading={textSummarizationMutation.isLoading}
            onClick={generateTextSummary}
          >
            {`${emojiMap.sparkles} Generate summary with AI ${emojiMap.sparkles}`}
          </SecondaryButton>
        </Center>
        {textSummarizationMutation.isLoading && <PatientVisitNoteLoader />}
        <Text>
          <Box dangerouslySetInnerHTML={{ __html: textSummary }} />
        </Text>
      </Stack>
    </VisitNoteSection>
  )
}
