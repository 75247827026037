import { EmrApi } from '@shared/types'
import { dayjs, toTime } from '@shared/utils'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { emrApi } from '../../api'

export const useDailyAvailableSlotsQuery = ({
  date,
  calendarId,
  appointmentTypeId,
  patientID,
}: {
  date: dayjs.Dayjs | undefined
  calendarId: number | undefined
  appointmentTypeId: string
  patientID: string
}) => {
  const formattedString = date?.format('MM/DD/YYYY')
  return useQuery(
    ...emrApi.getQuery('GET /appointments/calendar/:type/slots', {
      params: { type: appointmentTypeId },
      query: {
        date: formattedString ?? '',
        // Only provide calendarId if defined
        calendarId: calendarId ? `${calendarId}` : undefined,
        timezone: 'America/New_York',
        patientID,
      },
    }),
    {
      enabled: Boolean(date?.isValid()),
      cacheTime: toTime('10 sec').ms(),
    },
  )
}

export type CalendarSlotsData = {
  date: string
  loading: boolean
  slots: EmrApi['GET /appointments/calendar/:type/slots']['res']
}

export const useMultiAvailiabilityQuery = ({
  date,
  calendarId,
  appointmentTypeId,
  oneDayOnly,
  dateRange,
  patientID,
}: {
  date: string
  calendarId: number | undefined
  appointmentTypeId: string
  oneDayOnly: boolean
  dateRange: string[]
  patientID: string
}) => {
  const { firstDate, secondDate, thirdDate, fourthDate, fifthDate } = useMemo(() => {
    const firstDate = dayjs(date)
    let secondDate: dayjs.Dayjs | undefined
    let thirdDate: dayjs.Dayjs | undefined
    let fourthDate: dayjs.Dayjs | undefined
    let fifthDate: dayjs.Dayjs | undefined
    if (firstDate.isValid() && !oneDayOnly) {
      const startIndex = dateRange.findIndex(date => firstDate.isSame(date, 'day'))
      secondDate = dayjs(dateRange[startIndex + 1] ?? '')
      thirdDate = dayjs(dateRange[startIndex + 2] ?? '')
      fourthDate = dayjs(dateRange[startIndex + 3] ?? '')
      fifthDate = dayjs(dateRange[startIndex + 4] ?? '')
    }
    return { firstDate, secondDate, thirdDate, fourthDate, fifthDate }
  }, [date, oneDayOnly, dateRange])

  const firstDayQuery = useDailyAvailableSlotsQuery({
    date: firstDate,
    calendarId,
    appointmentTypeId,
    patientID,
  })
  const secondDayQuery = useDailyAvailableSlotsQuery({
    date: secondDate,
    calendarId,
    appointmentTypeId,
    patientID,
  })
  const thirdDayQuery = useDailyAvailableSlotsQuery({
    date: thirdDate,
    calendarId,
    appointmentTypeId,
    patientID,
  })
  const fourthDayQuery = useDailyAvailableSlotsQuery({
    date: fourthDate,
    calendarId,
    appointmentTypeId,
    patientID,
  })
  const fifthDayQuery = useDailyAvailableSlotsQuery({
    date: fifthDate,
    calendarId,
    appointmentTypeId,
    patientID,
  })
  return useMemo(() => {
    return [
      {
        date: firstDate.toString(),
        slots: firstDayQuery.data ?? [],
        loading: firstDayQuery.isLoading,
      },
      {
        date: secondDate?.toString() ?? '',
        slots: secondDayQuery.data ?? [],
        loading: secondDayQuery.isLoading,
      },
      {
        date: thirdDate?.toString() ?? '',
        slots: thirdDayQuery.data ?? [],
        loading: thirdDayQuery.isLoading,
      },
      {
        date: fourthDate?.toString() ?? '',
        slots: fourthDayQuery.data ?? [],
        loading: fourthDayQuery.isLoading,
      },
      {
        date: fifthDate?.toString() ?? '',
        slots: fifthDayQuery.data ?? [],
        loading: fifthDayQuery.isLoading,
      },
    ]
  }, [
    fifthDate,
    fifthDayQuery.data,
    fifthDayQuery.isLoading,
    firstDate,
    firstDayQuery.data,
    firstDayQuery.isLoading,
    fourthDate,
    fourthDayQuery.data,
    fourthDayQuery.isLoading,
    secondDate,
    secondDayQuery.data,
    secondDayQuery.isLoading,
    thirdDate,
    thirdDayQuery.data,
    thirdDayQuery.isLoading,
  ])
}
