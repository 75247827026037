import { createFormContext } from '@mantine/form'
import { SearchIcon, Select, SelectItem, Stack, Text } from '@shared/components'
import {
  EMRTaskPod,
  EMRTaskPodLabels,
  Employee,
  OnCallProvider,
  getPodOptions,
  hasGroupRole,
  hasRole,
} from '@shared/types'
import { useEffect, useMemo } from 'react'
import { useAuth } from '../../../context/auth'
import { useEmployees } from '../../../utils/hooks'
import { canViewIssues } from './canViewIssues'

type AssigneeForm = {
  pod: EMRTaskPod | ''
  employeeId: string
}
export const [AssigneeFormProvider, useAssigneeFormContext, useAssigneeForm] =
  createFormContext<AssigneeForm>()

const CARE_TEAM_PODS: EMRTaskPod[] = ['pes', 'pss']

export const AssigneeForm = ({
  currentOcp,
  onCallEngineer,
  isLoading,
}: {
  currentOcp?: OnCallProvider | null
  onCallEngineer?: Employee
  isLoading: boolean
}) => {
  const { currentUser } = useAuth()
  const assignForm = useAssigneeFormContext()

  const employeesQuery = useEmployees({ status: 'currentEmployee' })
  const employeesList = useMemo<SelectItem[]>(() => {
    const employees = (employeesQuery.data || [])
      .filter(canViewIssues)
      .filter(employee => {
        if (CARE_TEAM_PODS.includes(assignForm.values.pod as EMRTaskPod)) {
          return hasGroupRole(employee, 'careCoordinator')
        }

        if (assignForm.values.pod) {
          const currentFilter = EMRTaskPodLabels[assignForm.values.pod]
          if (currentFilter) {
            return hasRole(employee, ...currentFilter.value)
          }
        }

        return false
      })
      .map(employee => ({
        value: employee.oid,
        label: employee.name,
      }))

    const employeesWithoutSelf = employees.filter(({ value }) => value !== currentUser.oid)

    if (employeesWithoutSelf.length !== employees.length) {
      return [
        {
          value: currentUser.oid,
          label: `Assign to myself (${currentUser.name})`,
        },
      ].concat(employeesWithoutSelf)
    }

    return employees
  }, [currentUser.name, currentUser.oid, employeesQuery.data, assignForm.values.pod])

  const showEmployeeSelect = () => {
    if (!assignForm.values.pod) {
      return false
    }
    // Only allow engineers to assign tasks to other engineers
    if (assignForm.values.pod === 'engineer' && hasRole(currentUser, 'engineer')) {
      return true
    }
    // Don't allow selecting an engineer for non-engineers or ocp
    if (assignForm.values.pod === 'engineer' || assignForm.values.pod === 'ocp') {
      return false
    }
    return true
  }

  const getExplanationText = () => {
    if (assignForm.values.pod === 'ocp') {
      const currentOcpExplanation = currentOcp?.employeeId
        ? `${currentOcp.employeeName}, the current OCP, will be assigned and notified`
        : `There is no current OCP, so this request will be added to the queue`

      return currentOcpExplanation
    }
    if (assignForm.values.pod === 'engineer' && !hasRole(currentUser, 'engineer')) {
      const onCallEngineerExplanation = onCallEngineer?.name
        ? `${onCallEngineer?.name}, the current on call engineer, will be assigned and notified.`
        : `There is no current on call engineer, so this request will be added to the queue`

      return onCallEngineerExplanation
    }
    return ''
  }

  useEffect(() => {
    // Remove selected employeeId if not in list
    if (!employeesList.some(({ value }) => value === assignForm.values.employeeId)) {
      assignForm.setValues({ employeeId: '' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeesList])

  useEffect(() => {
    // Set employeeId to currentOcp if the ocp pod is selected
    if (assignForm.values.pod === 'ocp') {
      assignForm.setValues({ employeeId: currentOcp?.employeeId ?? '' })
    }
    // Set employeeId to onCallEngineer if the engineer pod is selected
    if (assignForm.values.pod === 'engineer') {
      assignForm.setValues({ employeeId: onCallEngineer?.oid ?? '' })
    }
  }, [assignForm.values.pod, currentOcp?.employeeId, onCallEngineer])

  return (
    <Stack>
      <Select
        searchable
        label='Assign to'
        placeholder='Search by pod...'
        icon={<SearchIcon />}
        data={getPodOptions()}
        disabled={isLoading}
        explanation={getExplanationText()}
        {...assignForm.getInputProps('pod')}
      />
      {showEmployeeSelect() && (
        <Select
          searchable
          clearable
          explanation='Search by full first name and/or last name'
          label='Care team member (optional)'
          placeholder='Search by name...'
          icon={<SearchIcon />}
          data={employeesList}
          disabled={isLoading}
          key={assignForm.values.employeeId}
          {...assignForm.getInputProps('employeeId')}
        />
      )}
      {hasRole(currentUser, 'engineer') && assignForm.values.pod === 'engineer' && (
        <Text size='xs' color={colors => colors.text[1]} bold>
          If no engineer is selected, the task will be assigned to the current on call engineer (
          {onCallEngineer?.name}).
        </Text>
      )}
    </Stack>
  )
}
