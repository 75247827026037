/* eslint-disable no-magic-numbers */
import { useClipboard, useToggle } from '@mantine/hooks'
import {
  Avatar,
  BellIcon,
  Box,
  Flex,
  FolderIcon,
  GiftIcon,
  Group,
  MailIcon,
  MapPinIcon,
  MessageCircleIcon,
  MinusCircleIcon,
  Modal,
  PhoneIcon,
  Pill,
  PlusCircleIcon,
  RotateCwIcon,
  SecondaryButton,
  ShieldIcon,
  SlidersIcon,
  Space,
  Stack,
  Tabs,
  TertiaryButton,
  Text,
  TitleTwo,
  Tooltip,
  UnstyledButton,
  UserCheckIcon,
  emojiMap,
  useMantineTheme,
} from '@shared/components'
import {
  PSYCH_DIAGNOSIS_CODES,
  PatientStatus,
  PsychDiagnosisCode,
  dischargeStatuses,
  hasGroupRole,
  hasHomeState,
  hasStatus,
} from '@shared/types'
import { KEYSTONE_FIRST_PAYER_ID, dayjs, isPayerInNetwork, phone } from '@shared/utils'
import capitalize from 'lodash/capitalize'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { emrApi } from '../../api'
import { NotificationsIndicators } from '../../components/templates/TDefault'
import { useAuth } from '../../context/auth'
import { useEmrQuery, useFlags, usePatientInsurances } from '../../utils/hooks'
import { useOrphanedConversation } from '../../utils/hooks/use-orphaned-conversation'
import { CommunicationsDrawer } from '../care_team/tasks/communications/CommunicationsDrawer'
import CocmIneligibleModal from '../registry/CocmIneligibleModal'
import ActionsDropdown from './ActionsDropdown'
import LinksDropdown from './LinksDropdown'
import { usePatient } from './PPatientContext'
import { useValidatePatientAuth } from './PatientProfile/hooks'
import { PatientShellHeaderLoading } from './PatientShellHeaderLoading'
import { TwilioStatus } from './TwilioStatus'
import { ConsentsPopover } from './journey/ConsentsPopover'
import { PatientTasksPopover } from './journey/PatientTasksPopover'

export type MPatientHeaderProps = {
  dateOfDischarge?: string
}

const patientShellHeaderTabs = [
  'blank',
  'chart',
  'edit',
  'labs',
  'files',
  'measures',
  'debug',
  'prescription-benefits',
  'invoices',
  'admin-tools',
] as const

type PatientShellHeaderTabString = (typeof patientShellHeaderTabs)[number]

const PatientShellHeaderTab = ({
  value,
  children,
}: {
  value: PatientShellHeaderTabString
  children: JSX.Element | string
}) => <Tabs.Tab value={value}>{children}</Tabs.Tab>

export const PatientShellHeader = () => {
  const theme = useMantineTheme()
  const navigate = useNavigate()
  const { currentUser } = useAuth()
  const [imageModal, setImageModal] = useToggle([false, true])
  const [showMore, setShowMore] = useToggle([true, false])
  const [cocmIneligibleModal, setCocmIneligibleModal] = useToggle([false, true])
  const [showCommunicationsDrawer, setShowCommunicationsDrawer] = useState<boolean>(false)
  const clipboard = useClipboard()
  const location = useLocation()
  const {
    isOrphanedConversation,
    handleCloseConversation,
    isLoading: isCloseConversationLoading,
  } = useOrphanedConversation()
  const { fortunaHealthPilotEmployees, twilioStatus, communicationsDrawer } = useFlags()

  const {
    patientQuery,
    patientImagesQuery,
    daysSinceLastVisitWithPcQuery,
    problemsListQuery,
    patientId,
  } = usePatient()
  const patientAuthQuery = useValidatePatientAuth()
  const patient = patientQuery?.data
  const daysSinceLastVisitWithPc = daysSinceLastVisitWithPcQuery?.data || null

  const tasksQuery = useEmrQuery('GET /patient/:patientId/tasks', { params: { patientId } })

  const isEligbleForCocmQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/is-eligible-for-cocm', {
      params: {
        patientId,
      },
    }),
  )

  // Get the page we're on - /patients/:patientId/edit -> edit
  const currentLinkEnd = location.pathname.split('/')[3]?.split('?')[0]

  let currentTab: string | undefined = 'blank'
  if (currentLinkEnd !== 'notes') {
    currentTab = patientShellHeaderTabs.includes(currentLinkEnd as PatientShellHeaderTabString)
      ? currentLinkEnd
      : 'chart'
  }

  const [cocmQueryKey, cocmQueryFunction] = emrApi.getQuery(
    'GET /cocmRegistry/patient/:patientId',
    { params: { patientId }, query: { includeDeleted: 'yes' } },
  )

  const patientCocmRegistry = useQuery(cocmQueryKey, cocmQueryFunction, {
    enabled: Boolean(patientId),
  })

  const isEligibleForCocm = isEligbleForCocmQuery.data === true
  const isParticipatingInCocm = patientCocmRegistry?.data?.deleted === false

  const referralPartnerQuery = useQuery(
    ...emrApi.getQuery('GET /referral-partners/:referralPartnerId', {
      params: {
        referralPartnerId: patient?.statuses?.referral?.referralPartnerId || '',
      },
    }),
    {
      enabled: Boolean(patient?.workflows?.onboarding?.metadata?.referralPartnerId),
    },
  )

  const calculateAge = (birthday: string) => {
    const birthDate = dayjs(birthday, 'MM/DD/YYYY')
    const today = dayjs()
    return today.diff(birthDate, 'years')
  }

  const [consentsQueryKey, consentsQueryFunction] = emrApi.getQuery(
    'GET /patient/:patientId/consents',
    { params: { patientId: patient?.oid ?? '' } },
  )

  const consentQuery = useQuery(consentsQueryKey, consentsQueryFunction, {
    enabled: Boolean(patient?.oid),
  })

  const requiredConsentsSigned =
    consentQuery?.data?.some(consent => consent.type === 'treatment') &&
    consentQuery?.data?.some(consent => consent.type === 'financial' && consent.version === 3)

  const patientInsurancesQuery = usePatientInsurances(patient)

  const { primaryInsurance } = patientInsurancesQuery.data || {}
  const hasCocmDiagnosis = () => {
    if (
      hasHomeState(patient, 'New York') ||
      primaryInsurance?.basicInsuranceData?.eligiblePayerId === KEYSTONE_FIRST_PAYER_ID
    ) {
      const problemList = problemsListQuery?.data ?? []
      return Boolean(
        problemList.find(problem => {
          return PSYCH_DIAGNOSIS_CODES.includes(problem.code as PsychDiagnosisCode)
        }),
      )
    }
    return true
  }

  const shouldShowDelaySubscription = () => {
    // no subscription if on allow list, payment delay is irrelevant
    if (patient?.statuses?.insuranceAllowList) {
      return false
    }
    const subscriptionData = patient?.subscription?.metadata
    if (!subscriptionData?.paymentDelay) {
      return false
    }
    if (Number(subscriptionData.paymentDelay) <= 0) {
      return false
    }
    // show for all non in treatment patients with a payment delay
    if (Boolean(patient) && !hasStatus(patient, 'in treatment', 'not responding', 'paused')) {
      return true
    }
    // show if the patient is in treatment but the subscription hasn't been created yet
    if (!subscriptionData.paymentStartDate) {
      return true
    }
    // if the subscription has been created but hasn't started yet, return true
    if (dayjs(subscriptionData.paymentStartDate).isAfter(dayjs())) {
      return true
    }
    return false
  }

  const patientHasMedicaid = primaryInsurance?.basicInsuranceData?.planType === 'MC'
  const patientInsuranceIsOON =
    primaryInsurance?.basicInsuranceData?.eligiblePayerId &&
    !isPayerInNetwork({
      payerId: primaryInsurance?.basicInsuranceData?.eligiblePayerId,
      state: patient?.homeData?.state,
    })
  const patientHasNoInsurance = !primaryInsurance?.basicInsuranceData?.eligiblePayerId
  const employeeParticipatingInFortunaHealthPilot = fortunaHealthPilotEmployees?.includes(
    currentUser.oid,
  )
  const patientLivesInNewYork = hasHomeState(patient, 'New York')
  const showFortunaHealthPilotPill =
    employeeParticipatingInFortunaHealthPilot &&
    patientLivesInNewYork &&
    (patientHasNoInsurance || (patientHasMedicaid && patientInsuranceIsOON))

  const fortunaHealthDetails = {
    showPill: showFortunaHealthPilotPill,
    tooltipText: patientHasNoInsurance
      ? 'NY patient with no insurance'
      : 'NY patient with OON Medicaid plan',
  }

  /*
   * If patient is lead or candidate, they shouldn't yet be added to insuranceAllowList, so check intendedPaymentMethod
   * Otherwise, check insuranceAllowList
   */
  const patientIsCashPay = ['lead', 'candidate'].includes(patient?.statuses?.patient || '')
    ? patient?.insuranceData?.intendedPaymentMethod === 'Cash' &&
      !patient?.statuses?.insuranceAllowList
    : !patient?.statuses?.insuranceAllowList

  const isLoading = patientQuery?.isLoading

  return (
    <>
      {imageModal && (
        <Modal
          title='ID Front'
          footer={null}
          opened={imageModal}
          onClose={() => {
            setImageModal(false)
          }}
        >
          <img src={patientImagesQuery?.data?.face || ''} />
        </Modal>
      )}
      <Box
        sx={theme => ({
          backgroundColor: theme.other.colors.background[1],
          position: 'sticky',
          top: 0,
          zIndex: 30,
        })}
        maw='none'
      >
        {isLoading && <PatientShellHeaderLoading />}
        {!isLoading && (
          <Stack spacing={0}>
            <Stack spacing='sm' mx='md' mt='md'>
              <Group position='apart'>
                <Group spacing='sm'>
                  <UnstyledButton onClick={() => setImageModal(true)} sx={{ cursor: 'pointer' }}>
                    <Avatar src={patientImagesQuery?.data?.face} />
                  </UnstyledButton>
                  <UnstyledButton
                    onClick={() =>
                      clipboard.copy(
                        `${patient?.personalData?.firstName} ${patient?.personalData?.lastName}`,
                      )
                    }
                    mt='sm'
                  >
                    {['twilio_ivr', 'twilio_ivr_call_us_now'].includes(
                      patient?.consents?.textMessageConsentSource || '',
                    ) && !patient?.personalData?.lastName ? (
                      <TitleTwo color={theme.other.colors.text[1]}>Inbound caller</TitleTwo>
                    ) : (
                      <TitleTwo>
                        {patient?.personalData?.lastName}, {patient?.personalData?.firstName}
                        {patient?.personalData?.preferredName &&
                          ` (${patient.personalData.preferredName})`}
                      </TitleTwo>
                    )}
                  </UnstyledButton>

                  <Box mt='xs'>
                    {showMore ? (
                      <MinusCircleIcon
                        styled
                        onClick={() => setShowMore(false)}
                        style={{ cursor: 'pointer' }}
                      />
                    ) : (
                      <PlusCircleIcon
                        styled
                        onClick={() => setShowMore(true)}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                  </Box>

                  {twilioStatus && (
                    <Box mt='xs'>
                      <TwilioStatus />
                    </Box>
                  )}
                </Group>
                <Group>
                  {daysSinceLastVisitWithPc !== null &&
                    daysSinceLastVisitWithPc > 50 &&
                    patient?.statuses?.patient === 'in treatment' && (
                      <Pill
                        status={
                          /* eslint-disable-next-line no-nested-ternary */
                          daysSinceLastVisitWithPc <= 65
                            ? 'success'
                            : daysSinceLastVisitWithPc <= 85 && daysSinceLastVisitWithPc > 65
                            ? 'warning'
                            : 'error'
                        }
                        variant='filled'
                      >
                        {`${daysSinceLastVisitWithPc} days since last PC visit`}
                      </Pill>
                    )}
                  {isParticipatingInCocm && (
                    <Pill status='success' variant='filled'>
                      Participating in CoCM
                    </Pill>
                  )}
                  {!isParticipatingInCocm &&
                    !isEligibleForCocm &&
                    hasGroupRole(currentUser, 'admin') && (
                      <UnstyledButton onClick={() => setCocmIneligibleModal(true)}>
                        <Pill status='none' variant='filled'>
                          Ineligible for CoCM
                        </Pill>
                      </UnstyledButton>
                    )}
                  {patientCocmRegistry?.data?.deleted && isEligibleForCocm && (
                    <Pill status='none' variant='filled'>
                      Discharged from CoCM
                    </Pill>
                  )}
                  {referralPartnerQuery.data && (
                    <Pill status='none' variant='filled'>
                      Referred by {referralPartnerQuery.data.name}
                    </Pill>
                  )}
                  {patient?.statuses?.patient === 'lead' &&
                    patient?.workflows?.onboarding?.status !== 'complete' && (
                      <Pill status='error' variant='filled'>
                        Welcome flow incomplete
                      </Pill>
                    )}
                  {cocmIneligibleModal && patient && (
                    <CocmIneligibleModal
                      patient={patient}
                      onClose={() => setCocmIneligibleModal(false)}
                      hasCocmDiagnosis={hasCocmDiagnosis()}
                      consentsSigned={Boolean(requiredConsentsSigned)}
                    />
                  )}
                  {!isLoading &&
                    !patientInsurancesQuery.isLoading &&
                    fortunaHealthDetails.showPill && (
                      <Tooltip label={fortunaHealthDetails?.tooltipText}>
                        <Pill status='success' variant='filled'>
                          Eligible for Fortuna Health Plan
                        </Pill>
                      </Tooltip>
                    )}
                  {shouldShowDelaySubscription() && (
                    <Tooltip label='Subscription delay'>
                      <Pill status='none' variant='filled'>
                        {patient?.subscription?.metadata.paymentDelay} days after intake
                      </Pill>
                    </Tooltip>
                  )}
                  {dischargeStatuses.includes(patient?.statuses.patient as PatientStatus) &&
                    patient?.discharge?.date && (
                      <Tooltip label={capitalize(patient.discharge.reason)}>
                        <Pill status='warning' variant='filled'>
                          Discharge on {patient.discharge.date}
                        </Pill>
                      </Tooltip>
                    )}
                  {patient && (
                    <CommunicationsDrawer
                      opened={showCommunicationsDrawer}
                      onClose={() => setShowCommunicationsDrawer(false)}
                      patient={patient}
                    />
                  )}
                  <NotificationsIndicators />
                </Group>
              </Group>
              {showMore && (
                <Group>
                  <Tooltip label='Click to copy'>
                    <Box
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        clipboard.copy(phone(String(patient?.personalData?.phone)).formatted)
                      }
                    >
                      <Group spacing='xs'>
                        <PhoneIcon
                          color={colors =>
                            !patientAuthQuery.data?.phone || patientAuthQuery.data.phone.isOwner
                              ? colors.background[4]
                              : colors.warning[0]
                          }
                        />
                        <Text size='md'>{phone(patient?.personalData.phone).formatted}</Text>
                      </Group>
                    </Box>
                  </Tooltip>
                  <Tooltip label='Click to copy'>
                    <Box style={{ cursor: 'pointer' }} onClick={() => clipboard.copy(patient?.oid)}>
                      <Group spacing='xs'>
                        <FolderIcon color={colors => colors.background[4]} />
                        <Text size='md'>{`${patient?.oid.slice(-6)}`}</Text>
                      </Group>
                    </Box>
                  </Tooltip>
                  {patient?.personalData.email && (
                    <Tooltip label='Click to copy'>
                      <Box
                        style={{ cursor: 'pointer' }}
                        onClick={() => clipboard.copy(patient.personalData.email.toLowerCase())}
                      >
                        <Group spacing='xs'>
                          <MailIcon
                            color={colors =>
                              !patientAuthQuery.data?.email || patientAuthQuery.data.email.isOwner
                                ? colors.background[4]
                                : colors.warning[0]
                            }
                          />
                          <Text size='md'>{patient.personalData.email}</Text>
                        </Group>
                      </Box>
                    </Tooltip>
                  )}
                  {patient?.personalData.sex && (
                    <Tooltip label='Sex/pronouns'>
                      <Group spacing='xs'>
                        <UserCheckIcon color={colors => colors.background[4]} />
                        <Text size='md'>
                          {capitalize(patient.personalData.sex)}
                          {patient.personalData.pronouns
                            ? ` (${patient.personalData.pronouns})`
                            : ''}
                        </Text>
                      </Group>
                    </Tooltip>
                  )}
                  {patient?.personalData.birthday && (
                    <Tooltip label='Click to copy'>
                      <Box
                        style={{ cursor: 'pointer' }}
                        onClick={() => clipboard.copy(patient.personalData.birthday)}
                      >
                        <Group spacing='xs'>
                          <GiftIcon color={colors => colors.background[4]} />
                          <Text size='md'>{`${patient.personalData.birthday} (${calculateAge(
                            patient.personalData.birthday,
                          )} yo)`}</Text>
                        </Group>
                      </Box>
                    </Tooltip>
                  )}
                  <Group spacing='xs'>
                    <MapPinIcon color={colors => colors.background[4]} />
                    <Text size='md'>{patient?.homeData?.state}</Text>
                  </Group>
                </Group>
              )}

              <Group>
                {patient?.primaryClinician?.name && (
                  <Group spacing='xs'>
                    <ShieldIcon color={colors => colors.background[4]} />
                    <Text size='md'>{`${patient?.primaryClinician?.name} (PC)`}</Text>
                  </Group>
                )}
                {patient?.nurseCareManager?.name && (
                  <Group spacing='xs'>
                    <ShieldIcon color={colors => colors.background[4]} />
                    <Text size='md'>{`${patient.nurseCareManager.name} (CCM)`}</Text>
                  </Group>
                )}
                {patient?.statuses?.patient && (
                  <Tooltip label='Patient status'>
                    <Group spacing='xs'>
                      <SlidersIcon color={colors => colors.background[4]} />
                      <Text size='md'>{capitalize(patient.statuses.patient)}</Text>
                    </Group>
                  </Tooltip>
                )}
                {patient?.statuses?.levelOfCare && (
                  <Tooltip label='Care level'>
                    <Group spacing='xs'>
                      <RotateCwIcon color={colors => colors.background[4]} />
                      <Text size='md'>{capitalize(patient.statuses.levelOfCare)}</Text>
                    </Group>
                  </Tooltip>
                )}
                {patient?.statuses?.insuranceAllowList && (
                  <Tooltip
                    label={
                      patient?.insuranceData?.allowListStatusChanged &&
                      `Since ${dayjs(patient.insuranceData.allowListStatusChanged).format(
                        'MM/DD/YYYY',
                      )}`
                    }
                  >
                    <Group spacing='xs'>
                      <ShieldIcon color={colors => colors.background[4]} />
                      <Text size='md'>Insurance pay</Text>
                    </Group>
                  </Tooltip>
                )}
                {patientIsCashPay && (
                  <Group spacing='xs'>
                    <ShieldIcon color={colors => colors.background[4]} />
                    <Text size='md'>Cash pay</Text>
                  </Group>
                )}
                <ConsentsPopover />
                <PatientTasksPopover
                  data={tasksQuery.data}
                  isLoading={tasksQuery.isLoading}
                  isError={tasksQuery.isError}
                />
                {patient?.statuses.patient === 'candidate' &&
                  patient?.account?.settings?.notifications?.standbyList?.initialVisit?.optIn && (
                    <Group spacing='xs'>
                      <BellIcon color={colors => colors.background[4]} />
                      <Text size='md'>Intake standby list</Text>
                    </Group>
                  )}
              </Group>
            </Stack>
            <Flex>
              <Tabs
                mt='md'
                keepMounted={false}
                value={currentTab}
                onTabChange={value => {
                  if (value === 'chart') {
                    navigate(`/patients/${patientId}`)
                  } else {
                    navigate(`/patients/${patientId}/${value}`)
                  }
                }}
              >
                <Tabs.List>
                  <Space w='md' />
                  <PatientShellHeaderTab value='chart'>Chart</PatientShellHeaderTab>
                  <PatientShellHeaderTab value='edit'>Profile</PatientShellHeaderTab>
                  <PatientShellHeaderTab value='labs'>Labs</PatientShellHeaderTab>
                  <PatientShellHeaderTab value='files'>Files</PatientShellHeaderTab>
                  <PatientShellHeaderTab value='measures'>Measures</PatientShellHeaderTab>
                  <PatientShellHeaderTab value='invoices'>Invoices</PatientShellHeaderTab>
                  {hasGroupRole(currentUser, 'engineer') && (
                    <>
                      <PatientShellHeaderTab value='debug'>Debug</PatientShellHeaderTab>
                      <PatientShellHeaderTab value='prescription-benefits'>
                        Rx Benefits
                      </PatientShellHeaderTab>
                      <PatientShellHeaderTab value='admin-tools'>Admin Tools</PatientShellHeaderTab>
                    </>
                  )}
                </Tabs.List>
              </Tabs>
              <Flex
                sx={({ other: { colors } }) => ({
                  borderBottomWidth: '2px',
                  flex: 1,
                  borderBottomColor: colors.actions[0],
                  justifyContent: 'flex-end',
                })}
              >
                <Group noWrap mr='md'>
                  {isOrphanedConversation && (
                    <Tooltip label='Conversation already active with patient'>
                      <TertiaryButton
                        onClick={handleCloseConversation}
                        disabled={isCloseConversationLoading}
                      >
                        {`${emojiMap.forbidden} Unblock`}
                      </TertiaryButton>
                    </Tooltip>
                  )}
                  {communicationsDrawer && patient && (
                    <Tooltip label='Communications'>
                      <SecondaryButton
                        leftIcon={<MessageCircleIcon />}
                        onClick={() => setShowCommunicationsDrawer(true)}
                      />
                    </Tooltip>
                  )}
                  <LinksDropdown />
                  <ActionsDropdown />
                </Group>
              </Flex>
            </Flex>
          </Stack>
        )}
      </Box>
    </>
  )
}
