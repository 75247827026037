import { Appointment, HDYHAUOptions, Patient, ReferralOption } from '@shared/types'
import { dayjs, toTime } from '@shared/utils'
import isEqual from 'lodash/isEqual'
import { useCallback, useEffect, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { emrApi } from '../../../api'
import ANoteDropdown from '../../../components/atoms/ANoteDropdown'
import ANoteHeader from '../../../components/atoms/ANoteHeader'
import ANoteSection from '../../../components/atoms/ANoteSection'
import ANoteSectionContent from '../../../components/atoms/ANoteSectionContent'
import ANoteTextArea from '../../../components/atoms/ANoteTextArea'
import ANoteTextInput from '../../../components/atoms/ANoteTextInput'
import ANoteYesNoRadioInput from '../../../components/atoms/ANoteYesNoRadioInput'
import { ReactHookFormErrorsObject } from '../../../utils/reactHookForm'
import { HighRiskPatientAlert } from './HighRiskPatientAlert'
import MVisitNoteSavingHeader from './MVisitNoteSavingHeader'

const defaultValues = {
  has_insurance: 'no',
  currentlyUsingOpioids: '',
  currentlyPrescribedBup: '',
  ableToGetPhysicalInThreeDays: '',
  referral: '',
}

const emptyForm = {
  has_insurance: 'no',
  insurance_provider: '',
  other_insurance_provider: '',
  past_buprenorphine_exposure: '',
  patient_story: '',
  treatment_history: '',
  currentlyUsingOpioids: '',
  currentlyPrescribedBup: '',
  ableToGetPhysicalInThreeDays: '',
}

const AdditionalInputForReferral = ({
  referral,
  referralPartners,
  register,
  editMode,
}: {
  referral?: string
  referralPartners: Record<string, string>
  register: <HTMLInputElement>() => (ref: HTMLInputElement | null) => void
  editMode: boolean
}) => {
  if (!referral) {
    return null
  }

  if (referral === 'provider') {
    return (
      <>
        <ANoteDropdown
          label='Official Ophelia referral partner?'
          name='referralPartnerId'
          id='referralPartnerId'
          options={referralPartners}
          ref={register()}
          disabled={!editMode}
          className='mt-4 col-span-6'
          naValue
        />
        <ANoteTextInput
          label='Name of health care provider'
          placeholder='Name of health care provider'
          name='provider'
          id='provider'
          ref={register()}
          disabled={!editMode}
          className='mt-4 col-span-6'
        />
        <ANoteTextInput
          label='Name of health care organization'
          placeholder='Name of health care organization'
          name='organization'
          id='organization'
          ref={register()}
          disabled={!editMode}
          className='mt-4 col-span-6'
        />
      </>
    )
  }

  return (
    <>
      <ANoteDropdown
        label='Official Ophelia referral partner?'
        name='referralPartnerId'
        id='referralPartnerId'
        options={referralPartners}
        ref={register()}
        disabled={!editMode}
        className='mt-4 col-span-6'
        naValue
      />
      <ANoteTextInput
        label='Share any helpful info'
        placeholder='Share any helpful info'
        name='additional'
        id='additional'
        ref={register()}
        disabled={!editMode}
        className='mt-4 col-span-6'
      />
    </>
  )
}

type OConsultationVisitFormProps = {
  patientID: string
  visitID: string
  editMode: boolean
  sidebar: boolean
  visit: Appointment | null
  setCloseNoteModal: (closeNoteModal: boolean) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  notes: Record<string, any> | null
  patient: Patient
}

const OConsultationVisitForm = ({
  patientID,
  visitID,
  editMode,
  sidebar,
  visit,
  setCloseNoteModal,
  notes,
  patient,
}: OConsultationVisitFormProps) => {
  const { errors, register, handleSubmit, setValue, watch } = useForm({ defaultValues })
  const [errorMessage, setErrorMessage] = useState('')
  const [savedAt, setSavedAt] = useState('')
  const [saving, setSaving] = useState(false)
  const [contentsToSave, setContentsToSave] = useState<FieldValues | null>(null)

  const masterWatcher = watch()
  const has_insurance = watch('has_insurance')
  const insurance_provider = watch('insurance_provider')
  const referral = watch('referral')

  const updateVisitNote = useMutation(
    emrApi.getMutation('PUT /patient/:patientId/visits/:visitId/notes'),
  )

  const isPaPatient = patient?.shippingData?.state === 'Pennsylvania'

  useEffect(() => {
    if (notes) {
      for (const [key, value] of Object.entries(notes)) {
        setValue(key, value)
      }
    }
  }, [notes, setValue, referral])

  const save = useCallback(
    async (data: FieldValues) => {
      const content = { ...data }
      if (isEqual(content, emptyForm)) {
        return
      }
      const body = {
        type: 'consultation' as const,
        content,
      }
      await updateVisitNote.mutateAsync(
        { params: { patientId: patientID, visitId: visitID }, data: body },
        {
          onSuccess: () => {
            setSavedAt(dayjs().format('MMMM Do YYYY, h:mm:ss a'))
            setErrorMessage('')
            setContentsToSave(data)
          },
          onError: () => {
            setErrorMessage(
              'Failed to save your last edits. Please try to save again or contact the engineering team.',
            )
            setCloseNoteModal(false)
          },
        },
      )
    },
    [editMode, patientID, visitID, visit, setCloseNoteModal],
  )

  const referralPartnersQuery = useQuery(...emrApi.getQuery('GET /referral-partners'))
  const referralPartners = referralPartnersQuery?.data || []
  const referralPartnersOptions: Record<string, string> = {}
  referralPartners.forEach(referralPartner => {
    referralPartnersOptions[referralPartner.name] = referralPartner.oid
  })

  useEffect(() => {
    const interval = setTimeout(async () => {
      if (!editMode) {
        return
      }
      if (isEqual(masterWatcher, contentsToSave)) {
        return
      }

      await save(masterWatcher)
    }, toTime('3 sec').ms())

    return () => clearTimeout(interval)
  }, [masterWatcher, editMode, save, contentsToSave])

  const saveNote = async () => {
    setSaving(true)
    await save(masterWatcher)
  }

  const onSubmit = async () => {
    setCloseNoteModal(true)
    setSaving(true)
    await save(masterWatcher)
  }

  useEffect(() => {
    const timer = setTimeout(() => setSaving(false), toTime('3 sec').ms())
    return () => clearTimeout(timer)
  }, [saving])

  const dateSigned = notes?.locked_at ? dayjs(notes.locked_at) : undefined
  const dateSignedFormatted = dateSigned?.isValid()
    ? dateSigned?.format('MMMM D, YYYY [at] h:mm a')
    : notes?.locked_at

  // Stored as { label, value }[] in @shared, but need to adjust to { label: value } map for ANoteDropdown
  const hdyauOptions: Record<string, ReferralOption> = {}
  HDYHAUOptions.forEach(option => {
    hdyauOptions[option.label] = option.value
  })

  return (
    <form className='w-full' onSubmit={handleSubmit(onSubmit)}>
      {notes && dateSigned && (
        <p className='mt-3 text-xs text-gray-500'>
          {`Signed on ${dateSignedFormatted} by ${notes?.locked_by_name}`}
          <br />
        </p>
      )}
      {!sidebar && (
        <div className='sticky top-0 bg-white border-b border-gray-200 h-16 w-full z-10'>
          <div className='flex flex-row h-full'>
            <div className='flex justify-start items-center flex-grow-1 flex-shrink-0'>
              {errorMessage && editMode && (
                <p className='text-xs text-red-800 font-semibold'>{errorMessage}</p>
              )}
              {savedAt && editMode && (
                <p className='text-xs text-gray-500'>Last saved at: {savedAt}</p>
              )}
            </div>
            {editMode && (
              <MVisitNoteSavingHeader
                dateTime={visit?.datetime}
                saveNote={saveNote}
                saving={saving}
                editMode={editMode}
                primaryButtonMessage='Finish'
              />
            )}
          </div>
        </div>
      )}
      {visit?.metadata?.highRiskPatient && <HighRiskPatientAlert />}
      <ANoteSection hideBorder={!isPaPatient}>
        <ANoteHeader text='How did this patient hear about us?' />
        <ANoteSectionContent className='grid gap-y-2 grid-cols-6'>
          <ANoteDropdown
            label=''
            name='referral'
            id='referral'
            ref={register()}
            options={hdyauOptions}
            disabled={!editMode}
            naValue
            error={errors}
            className='col-span-4'
          />

          <AdditionalInputForReferral
            referral={referral}
            referralPartners={referralPartnersOptions}
            register={register}
            editMode={editMode}
          />
        </ANoteSectionContent>
      </ANoteSection>
      <ANoteSection>
        <ANoteHeader text='Patient Story' />
        <ANoteSectionContent>
          <ANoteTextArea
            name='patient_story'
            id='patient_story'
            minRows={2}
            ref={register()}
            disabled={!editMode}
            error={errors as ReactHookFormErrorsObject}
          />
        </ANoteSectionContent>
      </ANoteSection>
      <ANoteSection>
        <ANoteHeader text='Medical and Treatment History' />
        <ANoteSectionContent>
          <ANoteTextArea
            name='treatment_history'
            id='treatment_history'
            minRows={2}
            ref={register()}
            disabled={!editMode}
            error={errors as ReactHookFormErrorsObject}
          />
        </ANoteSectionContent>
      </ANoteSection>
      <ANoteSection>
        <ANoteHeader text='Past Buprenorphine Exposure' />
        <ANoteSectionContent className='grid gap-y-2 grid-cols-6'>
          <ANoteDropdown
            label=''
            name='past_buprenorphine_exposure'
            id='past_buprenorphine_exposure'
            ref={register()}
            options={[
              'Transferring Suboxone treatment from prescriber',
              'Transferring Suboxone treatment from illicit source',
              'Previously on Suboxone maintenance',
              'Tried Suboxone in the past',
              'Never tried Suboxone',
            ]}
            disabled={!editMode}
            naValue
            error={errors as ReactHookFormErrorsObject}
            className='col-span-3'
          />
        </ANoteSectionContent>
      </ANoteSection>
      <ANoteSection>
        <ANoteHeader text='Insurance Eligibility' />
        <ANoteSectionContent className='grid gap-y-2 gap-x-4 grid-cols-6'>
          <ANoteYesNoRadioInput
            name='has_insurance'
            id='has_insurance'
            ref={register()}
            label='Do you have insurance?'
            disabled={!editMode}
            error={errors as ReactHookFormErrorsObject}
            className='col-span-6'
          />
          <ANoteTextInput
            name='other_insurance_provider'
            id='other_insurance_provider'
            ref={register()}
            label='Other Insurance Provider'
            disabled={!editMode}
            className={`${
              has_insurance === 'yes' && insurance_provider === 'Other' ? '' : 'hidden'
            } mt-4 col-span-3`}
          />
        </ANoteSectionContent>
      </ANoteSection>
    </form>
  )
}

export default OConsultationVisitForm
