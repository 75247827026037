import { useToggle } from '@mantine/hooks'
import {
  BellIcon,
  Box,
  CheckCircleIcon,
  Group,
  Indicator,
  Text,
  TitleFour,
  useMantineTheme,
} from '@shared/components'
import { toTime } from '@shared/utils'
import React, { PropsWithChildren, useState } from 'react'
import { useQuery } from 'react-query'
import { emrApi } from '../../api'
import { useAuth } from '../../context/auth'
import MAlertsDrawer from '../../pages/core/action_items/MAlertsDrawer'
import MAlertsDropdown from '../../pages/core/action_items/MAlertsDropdown'
import { NotificationsDrawer } from '../../pages/core/notifications/NotificationsDrawer'
import { useAlertsCount } from '../../utils/hooks'
import { OutageBanner } from '../../utils/hooks/use-outage-banner'

export const NotificationsIndicators = () => {
  const { currentUser } = useAuth()

  const [notificationsDropdown, setNotificationsDropdown] = useState(false)
  const [isNotificationsDrawerOpened, toggleNotificationsDrawer] = useToggle()

  const [, actionItemsCount, legacyNotificationsCount, , refreshAlertsCount] = useAlertsCount({
    employeeId: currentUser.oid,
  })
  const [isActionItemsDrawerOpened, toggleActionItemsDrawer] = useToggle()

  const [unseenNotificationsCountQueryKey, unseenNotificationsCountQueryFunction] = emrApi.getQuery(
    'GET /emrNotifications/count',
    {
      query: {
        employeeId: currentUser.oid,
      },
    },
  )

  const unseenNotificationsCountQuery = useQuery({
    queryKey: unseenNotificationsCountQueryKey,
    queryFn: unseenNotificationsCountQueryFunction,
    enabled: Boolean(currentUser.oid),
    refetchOnWindowFocus: true,
    staleTime: toTime('30 sec').ms(),
  })

  const notificationsCount = unseenNotificationsCountQuery.data || 0

  const {
    other: { colors },
  } = useMantineTheme()

  const indicatorProps = (label: number) => ({
    label: (
      <Text color={colors.text[3]} size='xs'>
        {label}
      </Text>
    ),
    disabled: label === 0,
    color: colors.error[0],
  })

  return (
    <Group>
      <Indicator
        onClick={() => {
          toggleNotificationsDrawer()
        }}
        {...indicatorProps(notificationsCount)}
      >
        <BellIcon color={colors.background[5]} />
      </Indicator>

      <Indicator onClick={() => toggleActionItemsDrawer()} {...indicatorProps(actionItemsCount)}>
        <CheckCircleIcon color={colors.background[5]} />
      </Indicator>
      <NotificationsDrawer
        opened={isNotificationsDrawerOpened}
        onClose={() => toggleNotificationsDrawer(false)}
      />
      {notificationsDropdown && (
        <MAlertsDropdown
          currentUser={currentUser}
          setDropdown={setNotificationsDropdown}
          refreshAlertsCount={refreshAlertsCount}
          notificationsCount={legacyNotificationsCount}
        />
      )}
      <MAlertsDrawer
        currentUser={currentUser}
        isAlertsDrawerOpened={isActionItemsDrawerOpened}
        setAlertsDrawerOpened={toggleActionItemsDrawer}
        refreshAlertsCount={refreshAlertsCount}
      />
    </Group>
  )
}

export const Page = ({
  title,
  headerContent,
  pushHeaderContent = true,
  children,
}: PropsWithChildren<{
  title: string
  headerContent?: React.ReactNode
  pushHeaderContent?: boolean
}>) => {
  return (
    <>
      <OutageBanner />
      <Box
        mih='4rem'
        mah='4rem'
        sx={theme => ({ backgroundColor: theme.other.colors.background[1] })}
      >
        <Group position='apart' pl='md' sx={{ height: '100%' }}>
          <Group>
            <TitleFour ml='lg' color={colors => colors.text[1]}>
              {title}
            </TitleFour>
            {!pushHeaderContent && headerContent}
          </Group>
          <Group pr='md'>
            {pushHeaderContent && headerContent}
            <NotificationsIndicators />
          </Group>
        </Group>
      </Box>
      {children}
    </>
  )
}
