import {
  Alert,
  BookmarkIcon,
  Box,
  Drawer,
  Group,
  InfoIcon,
  LoadingOverlay,
  ProgressDots,
  Radio,
  Stack,
  Text,
} from '@shared/components'
import { AppointmentTypeString } from '@shared/types'
import { toTime } from '@shared/utils'
import { DischargeAlert } from '../../components/banners/DischargeAlert'
import { useEmrQuery } from '../../utils/hooks'
import { useVisitTypes } from './useVisitTypes'

type PickVisitTypeStepProps = {
  closeModal: () => void
  onNext: (args: { appointmentTypeId: string; appointmentType?: string }) => void
  patientId: string
  firstName: string
  daysSinceLastVisitWithPc?: number | null
  // An allowlist of visit type options. If not provided, all visit types will be shown.
  visitTypesAllowed?: AppointmentTypeString[]
}

export const PickVisitTypeStep = ({
  closeModal,
  patientId,
  onNext,
  firstName,
  daysSinceLastVisitWithPc,
  visitTypesAllowed,
}: PickVisitTypeStepProps) => {
  const { visitTypes: allVisitTypes, isLoading } = useVisitTypes({ patientId })
  const patientQuery = useEmrQuery('GET /patient/:patientId', {
    params: { patientId },
  })

  let visitTypes = allVisitTypes
  if (visitTypesAllowed) {
    visitTypes = allVisitTypes.filter(
      visitType =>
        visitTypesAllowed?.some(
          allowedType => allowedType.toLowerCase() === visitType.label.toLowerCase(),
        ),
    )
  }

  return (
    <Drawer
      title='Schedule a visit'
      size='xl'
      opened
      position='right'
      onClose={closeModal}
      footer={
        <Group position='apart'>
          <ProgressDots steps={2} currentStep={0} />
        </Group>
      }
    >
      <Stack px='md'>
        <Box mx='md'>
          <DischargeAlert patient={patientQuery.data} />
        </Box>
        {/* eslint-disable-next-line no-magic-numbers */}
        {(daysSinceLastVisitWithPc ?? -1) >= toTime('30 days').val() && (
          <Alert variant='secondary' icon={<InfoIcon />}>
            {`${firstName} hasn't been seen by their PC in ${daysSinceLastVisitWithPc} days. Patients must be seen by their PC quarterly.`}
          </Alert>
        )}
        <Text>What type of visit would you like to schedule?</Text>
        {!isLoading && (
          <Stack spacing='sm'>
            {visitTypes.map(item => {
              const entry = (
                <Radio
                  key={item.value}
                  value={item.value}
                  onChange={() =>
                    onNext({ appointmentTypeId: item.value, appointmentType: item.label })
                  }
                  label={item.label}
                  iconless
                />
              )

              if (item.alert) {
                return (
                  <>
                    <Alert
                      key={item.alert.title}
                      variant='warning'
                      title={item.alert.title}
                      icon={<BookmarkIcon />}
                      actionIcon={undefined}
                    >
                      {item.alert.description}
                    </Alert>
                    {entry}
                  </>
                )
              }

              return entry
            })}
          </Stack>
        )}
      </Stack>
      <LoadingOverlay visible={isLoading} />
    </Drawer>
  )
}
