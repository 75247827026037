import { Alert, InfoIcon, LoadingOverlay } from '@shared/components'
import { Appointment, EMRTask, EMRTaskNote, Employee } from '@shared/types'
import { dayjs } from '@shared/utils'
import { Dispatch, ReactNode, SetStateAction } from 'react'
import { CalendarDrawer } from './CalendarDrawer'
import { SchedulerDrawer } from './SchedulerDrawer'
import { useAvailableEmployees } from './SelectClinician'

export type OpenWinbacks =
  | (EMRTask & {
      wrapUpNote?: EMRTaskNote<'note_added'> | undefined
    })[]
  | undefined

export type PickSlotStepProps = {
  closeModal: () => void
  rescheduleAppointment: Appointment | undefined
  appointmentTypeId: string
  onBack?: () => void | undefined
  patientId: string
  header?: ReactNode
  hasInsurance: boolean
  openWinbacks?: OpenWinbacks
  setStep?: Dispatch<SetStateAction<'visit' | 'confirm' | 'schedule' | 'close winback'>>
  onSuccess?: (args: {
    datetime: dayjs.Dayjs
    employee: Employee | undefined
    appointmentTypeName: string
    appointmentTypeId: string
  }) => void
}

export const PickSlotStep = ({
  appointmentTypeId,
  rescheduleAppointment,
  patientId,
  ...props
}: PickSlotStepProps) => {
  const {
    employees,
    recommendedOptions,
    otherOptions,
    isLoading,
    isInitialVisit,
    patient,
    appointmentTypes,
  } = useAvailableEmployees({
    patientId,
    appointmentTypeId,
  })

  if (isLoading) {
    return (
      <CalendarDrawer
        appointmentTypes={appointmentTypes}
        appointmentTypeId={appointmentTypeId}
        rescheduleAppointment={rescheduleAppointment}
        onBack={props.onBack}
        onClose={props.closeModal}
      >
        <LoadingOverlay visible />
      </CalendarDrawer>
    )
  }

  if (!patient || !employees) {
    return (
      <CalendarDrawer
        appointmentTypes={appointmentTypes}
        appointmentTypeId={appointmentTypeId}
        rescheduleAppointment={rescheduleAppointment}
        onBack={props.onBack}
        onClose={props.closeModal}
      >
        <Alert variant='warning' icon={<InfoIcon />}>
          Something went wrong!
        </Alert>
      </CalendarDrawer>
    )
  }

  return (
    <SchedulerDrawer
      {...props}
      appointmentTypeId={appointmentTypeId}
      rescheduleAppointment={rescheduleAppointment}
      patientId={patientId}
      setStep={props.setStep}
      openWinbacks={props.openWinbacks}
      patient={patient}
      employees={employees}
      recommendedOptions={recommendedOptions}
      otherOptions={otherOptions}
      isInitialVisit={isInitialVisit}
      appointmentTypes={appointmentTypes}
    />
  )
}
