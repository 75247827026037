import { Notification } from '@shared/types'
import { BaseApi } from '@shared/utils'

export class NotificationsApi extends BaseApi {
  getUnreadNotifications = ({ employeeId }: { employeeId: string }) => {
    return this.json<Notification[]>({
      method: 'GET',
      url: `/employee/${employeeId}/notifications/unread`,
    })
  }

  getUnreadNotificationsCount = ({ employeeId }: { employeeId: string }) => {
    return this.json<number>({
      method: 'GET',
      url: `/employee/${employeeId}/notifications/count`,
    })
  }

  markAsSeen = ({ notificationId }: { notificationId: string }) => {
    return this.json<number>({
      method: 'PUT',
      url: `/notifications/${notificationId}/seen`,
    })
  }
}
