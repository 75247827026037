import { HDYHAUOptions } from '@shared/types'
import { useQuery } from 'react-query'
import { emrApi } from '../../../../../api'
import { WelcomeCallVisitNoteForm } from '../WelcomeCallVisitNote'
import { PatientVisitNoteNotDiscussedCheckbox } from '../visitNoteForm/PatientVisitNoteNotDiscussedCheckbox'
import { PatientVisitNoteSection } from '../visitNoteForm/PatientVisitNoteSection'
import { PatientVisitNoteSelect } from '../visitNoteForm/PatientVisitNoteSelect'
import { PatientVisitNoteTextInput } from '../visitNoteForm/PatientVisitNoteTextInput'

export const ReferralSection = ({ form }: { form: WelcomeCallVisitNoteForm }) => {
  const referralPartnersQuery = useQuery(...emrApi.getQuery('GET /referral-partners'))
  const referralPartners = referralPartnersQuery?.data || []
  const referralPartnersOptions = [
    ...referralPartners.map(rp => ({ value: rp.oid, label: rp.name })),
    'N/A',
  ]

  return (
    <PatientVisitNoteSection title='Referral'>
      <PatientVisitNoteSelect
        label='How did the patient hear about us?'
        placeholder='Select one...'
        data={HDYHAUOptions}
        {...form?.getInputProps('referral')}
      />
      {form.values.referral === 'provider' && (
        <>
          <PatientVisitNoteSelect
            label='Official Ophelia referral partner?'
            placeholder='Select one...'
            data={referralPartnersOptions}
            {...form?.getInputProps('referralPartnerId')}
          />
          <PatientVisitNoteTextInput
            label='Provider name, if not offical referral partner (optional)'
            placeholder='Provider name...'
            {...form?.getInputProps('provider')}
          />
          <PatientVisitNoteTextInput
            label='Organization name, if not offical referral partner (optional)'
            placeholder='Organization name...'
            {...form?.getInputProps('organization')}
          />
        </>
      )}
      {Boolean(form?.values.referral) && (
        <PatientVisitNoteTextInput
          placeholder='Share any helpful info...'
          {...form?.getInputProps('referral_additional_details')}
        />
      )}
      <PatientVisitNoteNotDiscussedCheckbox field='referral' form={form} />
    </PatientVisitNoteSection>
  )
}
