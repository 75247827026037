import { Pill, Tooltip } from '@shared/components'
import { Employee, hasGroupRole } from '@shared/types'
import { dayjs, sortBy, template } from '@shared/utils'
import capitalize from 'lodash/capitalize'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { emrApi } from '../api'
import type { Visit } from '../api/types'
import { useAuth } from '../context/auth'

export const VisitPillStatus = ({ visit }: { visit: Visit }) => {
  const [isHovered, setIsHovered] = useState(false)
  const { currentUser } = useAuth()

  const { data: employee } = useQuery(
    ...emrApi.getQuery('GET /employee/:employeeId', {
      params: { employeeId: visit.canceledById ?? '' },
    }),
    {
      enabled: Boolean(visit.canceledById) && isHovered,
    },
  )

  // Build out the label, optional adding the Zoom events
  const statusLabel = getStatusLabel(visit, employee)
  // This confusing ternary is because we only need newlines if there is a statusLabel
  const tooltipLabel = hasGroupRole(currentUser, 'engineer', 'careCoordinator')
    ? getZoomEventsLabel(visit) + (statusLabel ? `\n\n${statusLabel}` : '')
    : statusLabel

  const tooltipProps = {
    multiline: true,
    width: 325,
    label: tooltipLabel,
    onMouseEnter: () => setIsHovered(true),
    position: 'top',
    style: {
      whiteSpace: 'pre-line',
    },
  } as const

  switch (visit.status) {
    case 'no-show':
      return (
        <Tooltip {...tooltipProps}>
          <Pill variant='filled' status='error'>
            No show
          </Pill>
        </Tooltip>
      )
    case 'canceled':
      return (
        <Tooltip {...tooltipProps}>
          <Pill variant='filled' status='warning'>
            Canceled
          </Pill>
        </Tooltip>
      )
    case 'late-cancellation':
      return (
        <Tooltip {...tooltipProps}>
          <Pill variant='filled' status='error'>
            Late cancel
          </Pill>
        </Tooltip>
      )
    case 'attended':
      return (
        <Tooltip {...tooltipProps}>
          <Pill variant='filled' status='success'>
            Attended
          </Pill>
        </Tooltip>
      )
    default:
      return (
        <Tooltip
          position='top'
          label={getConfirmedLabel({
            confirmed: visit.confirmed,
            confirmedAt: visit.confirmedAt ?? '',
            confirmedBy: visit.confirmedBy ?? '',
            confirmationDue: '',
          })}
        >
          <ConfirmedPill confirmed={visit.confirmed} />
        </Tooltip>
      )
  }
}

export const ConfirmedPill = ({ confirmed }: { confirmed: boolean }) => {
  if (confirmed) {
    return (
      <Pill status='success' variant='filled'>
        Confirmed
      </Pill>
    )
  }

  return (
    <Pill status='warning' variant='filled'>
      Not confirmed
    </Pill>
  )
}

const getCancelationMessage = (visit: Visit, datetime: string) => {
  const expiredTasks = visit?.metadata?.expiredTasks
    ?.map(task => capitalize(task).replace('-', ' '))
    .join(', ')

  return template('Automatically canceled at {datetime}{openTasksMessage}', {
    datetime,
    openTasksMessage: expiredTasks
      ? template('. Open tasks at time of cancelation: {expiredTasks}.', {
          expiredTasks,
        })
      : '',
  })
}

const getZoomEventsLabel = (visit: Visit) => {
  const zoomEvents = (visit.zoomEvents || [])
    .sort(sortBy({ key: 'event_ts', order: 'ASC' }))
    .map(event => {
      const datetime = dayjs(event.event_ts).format('MM/DD h:mma z')
      const user = event.user_name
      const eventName = event.event.split('_').pop()
      return `${datetime}: ${user} ${eventName}`
    })
    .join('\n')

  if (!zoomEvents) {
    return 'No Zoom information.'
  }
  return zoomEvents
}

const getStatusLabel = (visit: Visit, employee: Employee | undefined) => {
  if (visit.canceled) {
    const datetime = dayjs(visit.canceledAt).format('MMMM D, YYYY h:mma z')
    if (visit.canceledBy) {
      if (visit.canceledBy === 'patient') {
        return `Canceled by patient at ${datetime}`
      } else if (visit.canceledBy === 'ophelia_employee') {
        return `Canceled by ${employee?.name ?? 'employee'} at ${datetime}`
      } else if (visit.canceledBy === 'system') {
        return getCancelationMessage(visit, datetime)
      }
    }

    return `Canceled at ${datetime}`
  }

  return ''
}

export const getConfirmedLabel = ({
  confirmed,
  confirmedAt,
  confirmedBy,
  confirmationDue,
}: {
  confirmed: boolean
  confirmedBy: string
  confirmedAt: string
  confirmationDue: string
}) => {
  if (confirmed) {
    const datetime = dayjs(confirmedAt).format('MM/DD/YYYY h:mma z')
    if (confirmedBy === 'patient') {
      return `Confirmed by patient at ${datetime}`
    } else if (confirmedBy === 'system') {
      return `Auto confirmed at ${datetime}`
    } else if (confirmedBy === 'ophelia_employee') {
      return `Manually confirmed at ${datetime}`
    }

    return `Confirmed at ${datetime}`
  }

  if (confirmationDue) {
    return `Must confirm by ${dayjs(confirmationDue).format('MM/DD/YYYY h:mma z')}`
  }

  return 'Not confirmed'
}
