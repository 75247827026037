import { Group } from '@shared/components'
import { EmrLunaApi } from '@shared/types'
import { useState } from 'react'
import { Page } from '../../components/templates/TDefault'
import { DropInClinicSelect } from './DropInClinicSelect'
import { DropInClinicTable } from './DropInClinicTable'

export type DropInClinic = EmrLunaApi['GET /drop-in-clinics']['res']['data'][0]

export const ClinicPage = () => {
  const [clinic, setClinic] = useState<DropInClinic | null>(null)

  return (
    <Page title='Clinic'>
      <Group px='lg' pt='lg' position='right'>
        <DropInClinicSelect onClinicChange={setClinic} clinic={clinic} />
      </Group>
      <DropInClinicTable clinic={clinic} />
    </Page>
  )
}
