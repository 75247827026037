import React, { useEffect, useState } from 'react'
import { AiFillTag } from '@react-icons/all-files/ai/AiFillTag'
import { IoMdExit } from '@react-icons/all-files/io/IoMdExit'
import { useNavigate } from 'react-router-dom'
import { notificationsApi } from '../../../api'
import { getTimeSinceAndTimeUnit } from '../../../utils/utils'

type MNotificationProps = {
  title: string
  message: string
  icon: string
  timestampCreated: string
  timestampMarkedAsSeen: string
  url: string
  notificationId: string
  setNotificationsDropdown: (visible: boolean) => void
  refetch: () => void
  readOnly: boolean
}

const MNotification = ({
  title,
  message,
  icon,
  timestampCreated,
  timestampMarkedAsSeen,
  url,
  notificationId,
  setNotificationsDropdown,
  refetch,
  readOnly,
}: MNotificationProps) => {
  const navigate = useNavigate()
  const [unread, setUnread] = useState(!timestampMarkedAsSeen)
  const [timeSince, setTimeSince] = useState('')

  useEffect(() => {
    const { timeSince, timeUnit } = getTimeSinceAndTimeUnit(timestampCreated)
    setTimeSince(`${timeSince} ${timeUnit} ago`)
  }, [timestampCreated])

  const getIcon = (icon: string) => {
    switch (icon) {
      case 'attestation':
        return (
          <div className='h-8 w-8 bg-gray-200 rounded-full flex items-center justify-center'>
            <AiFillTag className='h-5 w-5 text-gray-500 rounded-full' />
          </div>
        )
      case 'discharge':
        return (
          <div className='h-8 w-8 bg-gray-200 rounded-full flex items-center justify-center'>
            <IoMdExit className='h-5 w-5 text-gray-500 rounded-full' />
          </div>
        )
      default:
        return (
          <div className='h-8 w-8 bg-gray-200 rounded-full flex items-center justify-center'>
            <AiFillTag className='h-5 w-5 text-gray-500 rounded-full' />
          </div>
        )
    }
  }

  const decrementUnreadNotifications = async () => {
    // if readonly, do not clear notifications upon click
    if (readOnly) {
      return
    }
    await notificationsApi.markAsSeen({ notificationId })
    setUnread(false)
    refetch()
  }

  const goTo = async () => {
    await decrementUnreadNotifications()
    setNotificationsDropdown(false)
    navigate(url)
  }

  const clickUnreadBubble: React.MouseEventHandler<HTMLButtonElement> = async e => {
    e.stopPropagation()
    await decrementUnreadNotifications()
  }

  return (
    <li className='py-4 px-6 hover:bg-gray-100'>
      <div onClick={goTo} className='cursor-pointer flex space-x-3 focus:outline-none'>
        {getIcon(icon)}
        <div className='flex-1 space-y-1'>
          <div className='flex items-center justify-between'>
            <p className='text-sm font-semibold text-indigo-900'>{title}</p>
          </div>
          <div className='flex items-center justify-between'>
            <p className='text-left text-sm text-gray-500 mr-4'>{message}</p>
            {unread && (
              <button
                onClick={clickUnreadBubble}
                className='z-5 h-3 w-3 bg-indigo-500 rounded-full focus:outline-none flex-shrink-0 hover:bg-indigo-400'
              />
            )}
          </div>
          <p className='text-indigo-600 text-xs font-medium'>{timeSince}</p>
        </div>
      </div>
    </li>
  )
}

export default MNotification
