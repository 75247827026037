import { isNotEmpty, useForm } from '@mantine/form'
import { useToggle } from '@mantine/hooks'
import {
  Alert,
  Avatar,
  Banner,
  BetterModal,
  Center,
  ClockIcon,
  Colors,
  EM_DASH,
  Group,
  MantineIndicator,
  Menu,
  ModalContent,
  ModalFooter,
  ModalHeader,
  PrimaryButton,
  Radio,
  RadioGroup,
  SecondaryButton,
  Select,
  Stack,
  Text,
  Tooltip,
  useMantineTheme,
  validateWith,
} from '@shared/components'
import {
  Appointment,
  AvailabilityStatus,
  ISOString,
  PULLING_FORWARD_ANOTHER_APPOINTMENT_SCHEDULED_TODAY,
  PULLING_FORWARD_FOLLOW_UP_STANDBY_APPOINTMENT,
  PULLING_FORWARD_NEXT_APPOINTMENT,
  PUSHING_BACK_NEXT_APPOINTMENT,
} from '@shared/types'
import { dayjs, isTruthy, sortBy, template, toTime } from '@shared/utils'
import pluralize from 'pluralize'
import { useEffect, useState } from 'react'
import { useAuth } from '../context/auth'
import { isNumber, isRequired } from '../utils/formValidation'
import * as FullStory from '../utils/fullstory'
import { useLunaMutation, useLunaQuery } from '../utils/hooks'
import { SelectItem } from './forms/SelectItem'

type AppointmentInfo = {
  startsAt: ISOString
  duration: string
  appointmentId: number
  patientId: string
  patientName: string
}

type PullAppointmentForwardForm = {
  availabilityStatus: AvailabilityStatus | null
  selectedAppointmentId: Appointment['oid'] | null
}

type PushAppointmentBackForm = {
  // This value should be a number, but the radio group expects a string
  pushBackByMinutes: '2' | '5' | undefined
  appointmentId: string | undefined
}

const getSelectSecondaryText = (appointmentInfo: AppointmentInfo) => {
  return template(`{appointmentTime} ({appointmentDuration} min)`, {
    appointmentTime: dayjs(appointmentInfo.startsAt).format('h:mm A'),
    appointmentDuration: appointmentInfo.duration,
  })
}

type PullAppointmentForwardModalProps = {
  opened: boolean
  onClose: () => void
  nextAppointment: AppointmentInfo | null
  remainingAppointmentsToday: AppointmentInfo[]
}

const PullAppointmentForwardModal = ({
  opened,
  onClose,
  nextAppointment,
  remainingAppointmentsToday,
}: PullAppointmentForwardModalProps) => {
  const handleClose = () => {
    availabilityForm.reset()
    onClose()
  }

  const availabilityMutation = useLunaMutation('POST /scheduling/availability', {
    onSuccess: () => {
      const isPullingForwardNextAppointment =
        `${nextAppointment?.appointmentId}` === `${availabilityForm.values.selectedAppointmentId}`

      FullStory.event('Clinician Pulled Appointment Forward', {
        isPullingForwardNextAppointment: isPullingForwardNextAppointment ? 'true' : 'false',
        pulledForwardAt: dayjs().toISOString(),
        numMinutesUntilNextAppointment: dayjs(nextAppointment?.startsAt).diff(dayjs(), 'minutes'),
        nextAppointmentStartsAt: nextAppointment?.startsAt || '',
      })

      /*
       * If the appointment being pulled forward is not the next appointment and
       * the time until the next appointment is less than the duration of the
       * appointment being pulled forward, send a fullstory event
       */
      const minutesUntilNextAppointment = dayjs(nextAppointment?.startsAt).diff(dayjs(), 'minutes')
      const appointmentBeingPulledForward = remainingAppointmentsToday.find(
        appointment =>
          `${appointment.appointmentId}` === availabilityForm.values.selectedAppointmentId,
      )
      const isInsufficientTimeForAppointment =
        minutesUntilNextAppointment < Number(appointmentBeingPulledForward?.duration)

      if (
        !isPullingForwardNextAppointment &&
        appointmentBeingPulledForward &&
        isInsufficientTimeForAppointment
      ) {
        FullStory.event('Clinician Pulling Appointment Forward With Insufficient Time')
      }

      handleClose()
    },
  })

  const availabilityForm = useForm<PullAppointmentForwardForm>({
    initialValues: { availabilityStatus: null, selectedAppointmentId: null },
    validate: {
      availabilityStatus: validateWith(isNotEmpty('Select one')),
      selectedAppointmentId: validateWith(isRequired),
    },
  })

  const updateAvailability = () => {
    if (availabilityForm.validate().hasErrors) {
      return
    }

    if (
      !availabilityForm.values.availabilityStatus ||
      !availabilityForm.values.selectedAppointmentId
    ) {
      return
    }

    availabilityMutation.mutate({
      data: {
        availabilityStatus: availabilityForm.values.availabilityStatus,
        appointmentId: availabilityForm.values.selectedAppointmentId,
      },
    })
  }

  // Update the appointmentId when a new availabilityStatus is selected
  useEffect(() => {
    // If PULLING_FORWARD_NEXT_APPOINTMENT is selected, set the appointmentId to the nextAppointment id
    if (
      nextAppointment &&
      availabilityForm.values.availabilityStatus === PULLING_FORWARD_NEXT_APPOINTMENT
    ) {
      availabilityForm.setValues({
        selectedAppointmentId: `${nextAppointment.appointmentId}`,
      })
    }

    /*
     * If PULLING_FORWARD_ANOTHER_APPOINTMENT_SCHEDULED_TODAY is selected, remove the selected appointment id so that
     * the user is forced to make a new selection. The risk here is that the user initially selected the next
     * appointment, but then changed their mind and selected another appointment. In this case, the form would
     * be submittable with PULLING_FORWARD_ANOTHER_APPOINTMENT_SCHEDULED_TODAY and the next appointment id, which
     * is an incorrect combination
     */
    if (
      availabilityForm.values.availabilityStatus ===
      PULLING_FORWARD_ANOTHER_APPOINTMENT_SCHEDULED_TODAY
    ) {
      availabilityForm.setValues({
        selectedAppointmentId: null,
      })
    }
  }, [availabilityForm.values.availabilityStatus])

  /*
   * We should not allow clinicians to open this modal if they do not have any more appointments remaining
   * today, but this check appeases the type checker
   */
  if (!nextAppointment) {
    return null
  }

  const minutesUntilNextAppointment = dayjs(nextAppointment.startsAt).diff(dayjs(), 'minutes')

  /*
   * Format the remaining appointments for the dropdown. This list should not include the clinician's next patient
   * since we already allow the clinician to select the next patient in the list
   */
  const selectableRemainingAppointments = remainingAppointmentsToday.sort(
    sortBy({ key: 'startsAt', order: 'ASC' }),
  )

  return (
    <BetterModal opened={opened} onClose={handleClose}>
      <ModalHeader onClose={onClose}>👍 I&apos;m ready for a patient</ModalHeader>
      <ModalContent>
        <Stack>
          {availabilityMutation.isError && (
            <Banner type='error' label='Could not update availability, please try again later.' />
          )}
          <Alert variant='primary' icon={<ClockIcon />}>
            <Text color={colors => colors.text[2]}>
              {template(
                `Your next visit with {patientName} starts in {minutesUntilNextAppointment} {minMins}`,
                {
                  patientName: nextAppointment.patientName,
                  minutesUntilNextAppointment: `${minutesUntilNextAppointment}`,
                  minMins: pluralize('min', minutesUntilNextAppointment),
                },
              )}
            </Text>
          </Alert>
          <RadioGroup {...availabilityForm.getInputProps('availabilityStatus')}>
            <Radio
              label={`My next patient: ${nextAppointment.patientName}`}
              value={PULLING_FORWARD_NEXT_APPOINTMENT}
            />
            <Stack spacing='sm'>
              <Radio
                label='Another patient on my schedule today'
                value={PULLING_FORWARD_ANOTHER_APPOINTMENT_SCHEDULED_TODAY}
                disabled={!selectableRemainingAppointments.length}
              />
              {availabilityForm.values.availabilityStatus ===
                PULLING_FORWARD_ANOTHER_APPOINTMENT_SCHEDULED_TODAY && (
                <Select
                  placeholder='Select patient'
                  data={selectableRemainingAppointments.map(patientInfo => {
                    return {
                      value: `${patientInfo.appointmentId}`,
                      label: patientInfo.patientName,
                      description: getSelectSecondaryText(patientInfo),
                    }
                  })}
                  itemComponent={SelectItem}
                  {...availabilityForm.getInputProps('selectedAppointmentId')}
                />
              )}
            </Stack>
          </RadioGroup>
        </Stack>
      </ModalContent>
      <ModalFooter>
        <Group position='right'>
          <SecondaryButton onClick={handleClose}>Cancel, go back</SecondaryButton>
          <PrimaryButton onClick={updateAvailability} loading={availabilityMutation.isLoading}>
            Set myself as ready
          </PrimaryButton>
        </Group>
      </ModalFooter>
    </BetterModal>
  )
}

type PushAppointmentBackModalProps = {
  opened: boolean
  onClose: () => void
  currentAppointment: AppointmentInfo | null
  nextAppointment: AppointmentInfo | null
}

const PushAppointmentBackModal = ({
  opened,
  onClose,
  currentAppointment,
  nextAppointment,
}: PushAppointmentBackModalProps) => {
  const [selectedAppointment, setSelectedAppointment] = useState<AppointmentInfo | null>(
    currentAppointment || nextAppointment || null,
  )

  const handleClose = () => {
    availabilityForm.reset()
    onClose()
  }

  const availabilityMutation = useLunaMutation('POST /scheduling/availability', {
    onSuccess: () => {
      FullStory.event('Clinician Pushed Appointment Back', {
        pushBackByMinutes: Number(availabilityForm.values.pushBackByMinutes),
        pushedBackAt: dayjs().toISOString(),
        numMinutesUntilNextAppointment: dayjs(nextAppointment?.startsAt).diff(dayjs(), 'minutes'),
      })
      handleClose()
    },
  })

  const availabilityForm = useForm<PushAppointmentBackForm>({
    initialValues: {
      pushBackByMinutes: undefined,
      appointmentId: undefined,
    },
    validate: {
      pushBackByMinutes: validateWith(isRequired, isNumber),
      appointmentId: validateWith(isRequired, isNumber),
    },
  })

  const updateAvailability = () => {
    if (availabilityForm.validate().hasErrors) {
      return
    }

    /*
     * The validation above ensures that pushBackByMinutes exists,
     * but we need the following check to appease the typechecker
     */
    if (!availabilityForm.values.pushBackByMinutes || !availabilityForm.values.appointmentId) {
      return
    }

    availabilityMutation.mutate({
      data: {
        availabilityStatus: PUSHING_BACK_NEXT_APPOINTMENT,
        appointmentId: availabilityForm.values.appointmentId,
        pushBackByMinutes: Number(availabilityForm.values.pushBackByMinutes),
      },
    })
  }

  const minutesUntilNextAppointment = dayjs(nextAppointment?.startsAt).diff(dayjs(), 'minutes')

  /*
   * Users can only select which appointment they'd like to push back if they have both a current and next appointment.
   * If they only have one, we will only allow them to push back the appointment that they have.
   */
  const isAbleToSelectAppointment = currentAppointment && nextAppointment

  // If the user only has one appointment, we will default to that appointment
  useEffect(() => {
    if (!isAbleToSelectAppointment) {
      if (currentAppointment) {
        availabilityForm.setValues({
          appointmentId: `${currentAppointment.appointmentId}`,
        })
        return
      }

      if (nextAppointment) {
        availabilityForm.setValues({
          appointmentId: `${nextAppointment.appointmentId}`,
        })
      }
    }
  }, [isAbleToSelectAppointment, nextAppointment, currentAppointment])

  useEffect(() => {
    /*
     * We display the times that clinicians can push appointments back to (ie. if the next appointment starts at 11am,
     * we will show 11:02am and 11:05am as options). We need to update these times whenever the user updates which
     * appointment they'd like to push back
     */
    setSelectedAppointment(
      [currentAppointment, nextAppointment].find(
        appointment => appointment?.appointmentId === Number(availabilityForm.values.appointmentId),
      ) || null,
    )
  }, [availabilityForm.values.appointmentId])

  /*
   * We should not allow clinicians to open this modal if they do not have an appointment to push back,
   * and this check appeases the type checker
   */
  if (!currentAppointment && !nextAppointment) {
    return null
  }

  return (
    <BetterModal opened={opened} onClose={handleClose}>
      <ModalHeader onClose={onClose}>🏃‍♂️ I&apos;m running late</ModalHeader>
      <ModalContent>
        <Stack>
          {availabilityMutation.isError && (
            <Banner type='error' label='Could not update availability, please try again later.' />
          )}
          {/* Only show the banner if the clinician has an upcoming appointment */}
          {nextAppointment && (
            <Alert variant='primary' icon={<ClockIcon />}>
              <Text color={colors => colors.text[2]}>
                {template(
                  `Your next visit with {patientName} starts in {minutesUntilNextAppointment} {minMins}`,
                  {
                    patientName: nextAppointment?.patientName,
                    minutesUntilNextAppointment: `${minutesUntilNextAppointment}`,
                    minMins: pluralize('min', minutesUntilNextAppointment),
                  },
                )}
              </Text>
            </Alert>
          )}
          <Text>Which appointment would you like to push back?</Text>
          <RadioGroup {...availabilityForm.getInputProps('appointmentId')}>
            {currentAppointment && (
              <Radio
                label={template(`{formattedTime} {emDash} {patientName}`, {
                  formattedTime: dayjs(currentAppointment?.startsAt).format('h:mma'),
                  emDash: EM_DASH,
                  patientName: currentAppointment?.patientName,
                })}
                value={`${currentAppointment?.appointmentId}`}
                disabled={!isAbleToSelectAppointment}
              />
            )}
            {nextAppointment && (
              <Radio
                label={template(`{formattedTime} {emDash} {patientName}`, {
                  formattedTime: dayjs(nextAppointment?.startsAt).format('h:mma'),
                  emDash: EM_DASH,
                  patientName: nextAppointment?.patientName,
                })}
                value={`${nextAppointment?.appointmentId}`}
                disabled={!isAbleToSelectAppointment}
              />
            )}
          </RadioGroup>
          <Text>How much extra time would you like?</Text>
          <RadioGroup {...availabilityForm.getInputProps('pushBackByMinutes')}>
            <Radio
              label={template(`2 mins {emDash} {formattedTime}`, {
                emDash: EM_DASH,
                formattedTime: dayjs(selectedAppointment?.startsAt)
                  .add(2, 'minutes')
                  .format('h:mma'),
              })}
              value='2'
            />
            <Radio
              label={template(`5 mins {emDash} {formattedTime}`, {
                emDash: EM_DASH,
                formattedTime: dayjs(selectedAppointment?.startsAt)
                  .add(5, 'minutes')
                  .format('h:mma'),
              })}
              value='5'
            />
          </RadioGroup>
        </Stack>
      </ModalContent>
      <ModalFooter>
        <Group position='right'>
          <SecondaryButton onClick={handleClose}>Cancel, go back</SecondaryButton>
          <PrimaryButton onClick={updateAvailability} loading={availabilityMutation.isLoading}>
            Set myself on break
          </PrimaryButton>
        </Group>
      </ModalFooter>
    </BetterModal>
  )
}

const getTooltipLabel = ({
  availability,
  allUpcomingAppointments,
}: {
  availability: {
    status: AvailabilityStatus | null
    appointmentId: Appointment['oid'] | null
    expiresAt: ISOString | null
  } | null
  allUpcomingAppointments: AppointmentInfo[]
}) => {
  switch (availability?.status) {
    case PULLING_FORWARD_NEXT_APPOINTMENT:
    case PULLING_FORWARD_ANOTHER_APPOINTMENT_SCHEDULED_TODAY:
    case PULLING_FORWARD_FOLLOW_UP_STANDBY_APPOINTMENT: {
      const appointmentBeingPulledForward = allUpcomingAppointments.find(
        appointment => `${appointment.appointmentId}` === availability?.appointmentId,
      )

      if (!appointmentBeingPulledForward) {
        return `👍 Ready for patient`
      }

      return `👍 Ready for ${appointmentBeingPulledForward.patientName}`
    }
    case PUSHING_BACK_NEXT_APPOINTMENT:
      return `☕️ On break until ${dayjs(availability?.expiresAt).format('h:mma')}`
    default:
      return ''
  }
}

const getIndicatorColor = ({
  status,
  colors,
}: {
  status: AvailabilityStatus | null
  colors: Colors
}) => {
  switch (status) {
    case PULLING_FORWARD_NEXT_APPOINTMENT:
    case PULLING_FORWARD_ANOTHER_APPOINTMENT_SCHEDULED_TODAY:
    case PULLING_FORWARD_FOLLOW_UP_STANDBY_APPOINTMENT:
      return colors.success[0]
    case PUSHING_BACK_NEXT_APPOINTMENT:
      return colors.error[0]
    default:
      return colors.warning[0]
  }
}

export const ClinicianStatus = () => {
  const { currentUser } = useAuth()

  const {
    other: { colors },
  } = useMantineTheme()

  const [isPullingAppointmentForwardModalOpen, togglePullForwardModal] = useToggle()
  const [isPushAppointmentBackModalOpen, togglePushBackModal] = useToggle()

  const availabilityInfoQuery = useLunaQuery('GET /scheduling/availability', undefined, {
    // Refetch each minute to check if the clinician's status has changed
    refetchInterval: toTime('1 minute').ms(),
  })

  const availabilityInfo = availabilityInfoQuery.data?.data || {
    availability: {
      status: null,
      appointmentId: null,
      expiresAt: null,
    },
    currentAppointment: null,
    nextAppointment: null,
    remainingAppointmentsToday: [],
  }

  if (availabilityInfoQuery.isLoading) {
    return (
      <Center>
        <Avatar src={currentUser.profilePhotoURL} />
      </Center>
    )
  }

  const { availability, currentAppointment, nextAppointment, remainingAppointmentsToday } =
    availabilityInfo

  // Only allow clinicians to set themselves as unavailable if they have another appointment today
  const hasNextAppointmentToday = Boolean(nextAppointment)
  const isAbleToPullAppointmentForward = !availability?.status
  const isAbleToPushAppointmentBack = !availability?.status
  const isAbleToUpdateStatus =
    hasNextAppointmentToday && isAbleToPullAppointmentForward && isAbleToPushAppointmentBack

  return (
    <Center>
      <PullAppointmentForwardModal
        opened={isPullingAppointmentForwardModalOpen}
        onClose={() => {
          togglePullForwardModal(false)
          // Refetch the clinician's availability status when we close the modal in case we updated it
          void availabilityInfoQuery.refetch()
        }}
        nextAppointment={nextAppointment}
        remainingAppointmentsToday={remainingAppointmentsToday}
      />
      <PushAppointmentBackModal
        opened={isPushAppointmentBackModalOpen}
        onClose={() => {
          togglePushBackModal(false)
          // Refetch the clinician's availability status when we close the modal in case we updated it
          void availabilityInfoQuery.refetch()
        }}
        currentAppointment={currentAppointment}
        nextAppointment={nextAppointment}
      />
      <Tooltip
        label={getTooltipLabel({
          availability,
          allUpcomingAppointments: [nextAppointment, ...remainingAppointmentsToday].filter(
            isTruthy,
          ),
        })}
        disabled={isAbleToUpdateStatus}
        position='right-end'
      >
        <Menu position='top-start' disabled={!isAbleToUpdateStatus}>
          <Menu.Target>
            <MantineIndicator
              onClick={() => {}}
              color={getIndicatorColor({
                status: availability?.status || null,
                colors,
              })}
              // This specific offset aligns the indicator with the avatar
              offset={3}
              processing
              sx={{ cursor: isAbleToUpdateStatus ? 'pointer' : 'default' }}
              disabled={isAbleToUpdateStatus}
            >
              <Avatar src={currentUser.profilePhotoURL} />
            </MantineIndicator>
          </Menu.Target>
          {/* Hide the links if the clinician does not have any more appointments today */}
          {isAbleToUpdateStatus && (
            <Menu.Dropdown>
              {isAbleToPullAppointmentForward && (
                <Menu.Item
                  onClick={() => {
                    togglePullForwardModal(true)
                    FullStory.event('Clinician Selected To Pull Appointment Forward')
                  }}
                >
                  👍 I&apos;m ready for a patient
                </Menu.Item>
              )}
              {isAbleToPushAppointmentBack && (
                <Menu.Item
                  onClick={() => {
                    togglePushBackModal(true)
                    FullStory.event('Clinician Selected To Push Appointment Back')
                  }}
                >
                  🏃‍♂️ I&apos;m running late
                </Menu.Item>
              )}
            </Menu.Dropdown>
          )}
        </Menu>
      </Tooltip>
    </Center>
  )
}
