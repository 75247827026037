import { useToggle } from '@mantine/hooks'
import {
  CheckIcon,
  CopyIcon,
  EditIcon,
  Group,
  Menu,
  MoreVerticalIcon,
  SecondaryButton,
  Stack,
  Text,
  TrashIcon,
} from '@shared/components'
import { MutationModal } from '../../../../components/MutationModal'
import { useInvalidateLunaQuery } from '../../../../utils/hooks'
import { SmartPhrase } from '../../../employees/MEmployeesSmartPhrasesTab'
import { SmartPhraseAddOrEditDrawer } from '../../../employees/SmartPhraseAddOrEditDrawer'
import { SmartPhraseActionVariation } from './SmartPhraseTable'

type SmartPhraseActionsProps = {
  smartPhrase: SmartPhrase
  isCopied: boolean
  onCopySmartPhrase: () => void
  variation: SmartPhraseActionVariation
}

/*
 * We render this component in 2 places: (1) the employees page and (2) visit notes.
 * In the employees page, we want to let users edit or delete the smart phrase.
 * In visit notes, we want to allow users to copy the smart phrase to their clipboard.
 */
export const SmartPhraseActions = ({
  smartPhrase,
  isCopied,
  onCopySmartPhrase,
  variation,
}: SmartPhraseActionsProps) => {
  const invalidateLunaQuery = useInvalidateLunaQuery()

  const [isDisplayingDrawerToAddOrEdit, toggleAddOrEditDrawer] = useToggle()
  const [isDisplayingDeleteModal, toggleDeleteModal] = useToggle()

  switch (variation) {
    case 'editor': {
      return (
        <>
          <SmartPhraseAddOrEditDrawer
            opened={isDisplayingDrawerToAddOrEdit}
            onClose={() => toggleAddOrEditDrawer(false)}
            smartPhrase={smartPhrase}
          />
          <SmartPhraseAddOrEditDrawer
            opened={isDisplayingDrawerToAddOrEdit}
            onClose={() => toggleAddOrEditDrawer(false)}
            smartPhrase={smartPhrase}
          />
          <MutationModal
            header='Delete smart phrase'
            description={`Are you sure you want to delete the "${smartPhrase.name}" smart phrase?`}
            callToAction='Yes, delete'
            opened={isDisplayingDeleteModal}
            onClose={() => toggleDeleteModal(false)}
            endpoint='DELETE /smart-phrases/:smartPhraseId'
            payload={{
              params: {
                smartPhraseId: smartPhrase.oid,
              },
            }}
            onSuccess={() => {
              void invalidateLunaQuery('GET /smart-phrases')

              toggleDeleteModal(false)
            }}
            // Add a z-index so that the modal displays above the drawer
            zIndex={1000}
          />
          <Stack align='center'>
            <Menu position='bottom-end'>
              <Menu.Target>
                <SecondaryButton size='xs' leftIcon={<MoreVerticalIcon />} />
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item onClick={() => toggleAddOrEditDrawer(true)}>
                  <Group spacing='xs'>
                    <EditIcon color={colors => colors.actions[0]} />
                    <Text>Edit</Text>
                  </Group>
                </Menu.Item>
                <Menu.Item onClick={() => toggleDeleteModal(true)}>
                  <Group spacing='xs'>
                    <TrashIcon color={colors => colors.actions[0]} />
                    <Text>Delete</Text>
                  </Group>
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Stack>
        </>
      )
    }

    case 'copy-only': {
      return (
        <SecondaryButton
          leftIcon={isCopied ? <CheckIcon color={colors => colors.success[0]} /> : <CopyIcon />}
          onClick={onCopySmartPhrase}
        />
      )
    }

    /*
     * This component should be rendered in either the employees page or a visit note but,
     * if not, we'll return null
     */
    default:
      return null
  }
}
