import { useToggle } from '@mantine/hooks'
import {
  BetterDrawer,
  BetterModal,
  Checkbox,
  ChevronRightIcon,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Group,
  LoadingOverlay,
  ModalContent,
  ModalFooter,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
  SendIcon,
  Stack,
  Text,
  TextInput,
  TooltipLabel,
  validateWith,
} from '@shared/components'
import {
  DoseSpotPharmacy,
  DoseSpotPrescription,
  PrescriptionRequest,
  getOpheliaHttpError,
} from '@shared/types'
import { dayjs, isControlledSubstance } from '@shared/utils'
import Lottie from 'lottie-react'
import { useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { pharmaciesApi, prescriptionsApi } from '../../api'
import { PharmacyInactiveAlert } from '../../components/PharmacyInactive'
import { useAuth } from '../../context/auth'
import {
  inputNumberBetween,
  isDate,
  isNumber,
  isRequired,
  prescriptionPINInvalid,
} from '../../utils/formValidation'
import { useEmrQuery, useFlags } from '../../utils/hooks'
import { useGetPharmacy } from '../../utils/hooks/use-get-pharmacy'
import { useLottieAnimation } from '../../utils/hooks/use-lottie-animation'
import { buildSearchString, getPharmacyNotesTemplate } from '../../utils/prescriptionUtils'
import EditQueuedPrescriptionDrawer from './prescriptions/EditQueuedPrescriptionDrawer'
import MEditPharmacyForm from './prescriptions/MEditPharmacyForm'
import MPrescriptionPINForm from './prescriptions/MPrescriptionPINForm'
import MPrescriptionPreview from './prescriptions/MPrescriptionPreview'
import {
  MAX_REFILLS,
  MAX_REFILLS_NARCAN,
  MIN_REFILLS,
  MedicationFormData,
  MedicationFormProvider,
  controlledSubstanceValidationBuilder,
  useMedicationForm,
} from './prescriptions/formHelpers'
import { getDefaultLocationsData } from './visits/PatientAndClinicianLocations'

type PrescriptionSendModalProps = {
  patientId: string
  prescriptionId: number
  editOnly: boolean
  onClose: (force?: boolean) => void
  setPrescriptionBanner: (message: string, type: 'success' | 'warning' | 'error') => void
}
type QueueFormStep = 'Prescription Form' | 'Pharmacy Edit' | 'Preview'

const PrescriptionSendContent = ({
  patientId,
  prescriptionId,
  editOnly,
  setPrescriptionBanner,
  onClose,
}: PrescriptionSendModalProps) => {
  const { patientAndClinicianLocations } = useFlags()
  const [error, setError] = useState('')
  const [step, setStep] = useState<QueueFormStep>(editOnly ? 'Prescription Form' : 'Preview')
  const [hasPrescriptionBeenEdited, setHasPrescriptionBeenEdited] = useState<boolean>(false)
  const [tempSelectedPharmacy, setTempSelectedPharmacy] = useState<DoseSpotPharmacy>()
  const [shouldSetPreferredPharmacy, setShouldSetPreferredPharmacy] = useState<boolean>(false)
  const [employeeIsAtWorkAddress, toggleEmployeeIsAtWorkAddress] = useToggle([true, false])
  const queryClient = useQueryClient()
  const { currentUser } = useAuth()
  const animation = useLottieAnimation('send-animation')
  const forceClose = () => onClose(true)

  const getPrescriptionButtonContent = () => {
    if (step === 'Prescription Form') {
      return 'Review'
    }
    if (step === 'Pharmacy Edit') {
      return 'Save pharmacy'
    }
    if (step === 'Preview') {
      return editOnly ? 'Update' : 'Send'
    }
  }

  const { employee_address: defaultEmployeeAddress } = getDefaultLocationsData({
    employee: currentUser,
  })

  const skipIfLocationsSectionNotDisplayed = () => !patientAndClinicianLocations

  const medicationForm = useMedicationForm({
    initialValues: {
      medication_strength: '',
      medication_quantity: '',
      medication_days_supply: '',
      medication_refills: '',
      substitutionAllowed: true,
      bridge: false,
      directions: '',
      notes: getPharmacyNotesTemplate(),
      effective_date: dayjs().format('MM/DD/YYYY'),
      employeeAddress: defaultEmployeeAddress,
    },
    validate: {
      pin:
        editOnly || step !== 'Preview'
          ? undefined
          : validateWith(isRequired, prescriptionPINInvalid),
      effective_date: validateWith(isRequired, isDate),
      medication_strength: validateWith(isRequired),
      medication_refills: controlledSubstanceValidationBuilder({
        isControlled: [inputNumberBetween({ start: 0, end: 0 })],
        isNotControlled: [isRequired, inputNumberBetween({ start: MIN_REFILLS, end: MAX_REFILLS })],
        isNarcan: [inputNumberBetween({ start: MIN_REFILLS, end: MAX_REFILLS_NARCAN })],
      }),
      medication_days_supply: controlledSubstanceValidationBuilder({
        isControlled: [isRequired, isNumber],
        isNotControlled: [isNumber],
      }),
      medication_quantity: validateWith(isRequired, isNumber),
      directions: validateWith(isRequired),
      employeeAddress: validateWith(skipIfLocationsSectionNotDisplayed, isRequired),
    },
  })

  // We want to update the form value for the clinician's location as they toggle the checkbox indicating that they are at their work address
  useEffect(() => {
    medicationForm.setValues({
      employeeAddress: employeeIsAtWorkAddress ? defaultEmployeeAddress : '',
    })
  }, [employeeIsAtWorkAddress])

  const prescriptionSummary = useEmrQuery(
    'GET /patient/:patientId/prescription/:prescriptionId',
    {
      params: {
        patientId,
        prescriptionId: String(prescriptionId),
      },
    },
    {
      enabled: Boolean(prescriptionId),
    },
  )

  const sendPrescription = useMutation(prescriptionsApi.sendPrescription, {
    onSuccess: () => {
      forceClose()
      void queryClient.invalidateQueries()
      setPrescriptionBanner('Prescription successfully initiated', 'success')
    },
    onError: error => {
      setError(getOpheliaHttpError(error, 'Something went wrong'))
    },
  })

  const editPrescription = useMutation(prescriptionsApi.editPrescription, {
    onError: error => {
      setError(getOpheliaHttpError(error, 'Something went wrong'))
    },
  })

  const pharmacyId =
    medicationForm.values.pharmacyId || prescriptionSummary.data?.prescription.PharmacyId
  const { getPharmacyQuery, isPharmacyInactive } = useGetPharmacy({ pharmacyId, patientId })

  const updateDefaultPharmacy = useMutation(pharmaciesApi.update, {
    onSuccess: () => {
      void queryClient.invalidateQueries('pharmaciesApi.retrieve')
    },
  })

  const setPatientDefaultPharmacy = (pharmacyId: number) => {
    updateDefaultPharmacy.mutate({ patientId, pharmacyId })
  }

  const getSubmit = () => {
    if (step === 'Prescription Form') {
      if (medicationForm.validate().hasErrors) {
        return
      }
      setStep('Preview')
    }
    if (step === 'Pharmacy Edit') {
      if (!tempSelectedPharmacy) {
        return
      }
      if (shouldSetPreferredPharmacy) {
        setPatientDefaultPharmacy(tempSelectedPharmacy.PharmacyId)
      }
      setShouldSetPreferredPharmacy(false)
      medicationForm.setFieldValue('pharmacyId', tempSelectedPharmacy.PharmacyId)
      setStep('Prescription Form')
      return
    }
    if (step === 'Preview') {
      if (medicationForm.validateField('pin').hasError) {
        return
      }
      onSubmit(medicationForm.values.pin, medicationForm.values.twoFacCode)
    }
  }

  if (!prescriptionSummary.data) {
    return (
      <>
        <DrawerHeader onClose={onClose}>
          {editOnly ? 'Edit queued prescription' : 'Review & send prescription'}
        </DrawerHeader>
        <DrawerContent>
          <LoadingOverlay visible={prescriptionSummary.isFetching} />{' '}
        </DrawerContent>
      </>
    )
  }

  const { prescription } = prescriptionSummary.data

  const {
    DispenseUnitId,
    DoseForm,
    Route,
    Strength,
    GenericProductName,
    LexiGenProductId,
    LexiDrugSynId,
    LexiSynonymTypeId,
    LexiGenDrugId,
    RxCUI,
    OTC,
    NDC,
    Schedule,
    DisplayName,
    MonographPath,
    DrugClassification,
    StateSchedules,
    Brand,
  } = prescription

  const medicationSearchName = buildSearchString({
    displayName: prescription.DisplayName,
    strength: prescription.Strength,
    route: prescription.Route,
  })

  const data: MedicationFormData = {
    medication_strength: Strength,
    medication_days_supply: prescription.DaysSupply ? String(prescription.DaysSupply) : '',
    medication_quantity: prescription.Quantity ? String(prescription.Quantity) : '',
    medication_refills: prescription.Refills ? String(prescription.Refills) : '0',
    directions: prescription.Directions,
    notes: prescription.PharmacyNotes ?? '',
    bridge: prescription.NonDoseSpotPrescriptionId === 'bridge',
    substitutionAllowed: !prescription.NoSubstitutions,
    effective_date: dayjs(prescription.EffectiveDate).isValid()
      ? dayjs(prescription.EffectiveDate).format('MM/DD/YYYY')
      : dayjs().format('MM/DD/YYYY'),
    pharmacyId: prescription.PharmacyId,
    medication: {
      Name: medicationSearchName,
      Strength: [
        {
          Strength,
          NDC,
        },
      ],
    },
    full_medication: {
      DispenseUnitId,
      DoseForm,
      Route,
      Strength,
      GenericProductName,
      LexiGenProductId,
      LexiDrugSynId,
      LexiSynonymTypeId,
      LexiGenDrugId,
      RxCUI,
      OTC,
      NDC,
      Schedule,
      DisplayName,
      MonographPath,
      DrugClassification,
      StateSchedules: StateSchedules ?? [],
      Brand,
    },
  }

  const onSubmit = (pinCode?: string, twoFacCode?: string) => {
    if (!prescriptionSummary.data || !prescriptionId) {
      setError('An error has occurred.')
      return
    }

    if (!hasPrescriptionBeenEdited && !editOnly) {
      sendPrescription.mutate({
        pinCode,
        twoFacCode,
        queued: true,
        schedule: prescriptionSummary.data.prescription.Schedule ?? '',
        prescriptionId,
        doseSpotId: String(prescriptionSummary.data.prescription.PrescriberId),
        patientDoseSpotId: prescriptionSummary.data.patient?.doseSpotId ?? 0,
        employeeAddress: medicationForm.values.employeeAddress || '',
      })
      return
    }
    if (!hasPrescriptionBeenEdited && editOnly) {
      forceClose()
    }

    if (
      !medicationForm.values?.pharmacyId ||
      !medicationForm.values?.medication ||
      !medicationForm.values?.full_medication
    ) {
      setError('An error has occurred.')
      return
    }

    const medication: PrescriptionRequest = {
      patientId,
      medication_name: medicationForm.values.medication.Name,
      medication_strength: medicationForm.values.medication_strength,
      medication_refills: Number(medicationForm.values.medication_refills),
      medication_quantity: Number(medicationForm.values.medication_quantity),
      medication_days_supply: medicationForm.values.medication_days_supply
        ? Number(medicationForm.values.medication_days_supply)
        : undefined,
      directions: medicationForm.values?.directions,
      pharmacyId: medicationForm.values.pharmacyId,
      pinCode,
      twoFacCode,
      bridge: medicationForm.values.bridge,
      queuedForId: medicationForm.values.queuedForId,
      notes: medicationForm.values.notes,
      substitutionAllowed: medicationForm.values.substitutionAllowed,
      type: 'queuing',
      effective_date: medicationForm.values?.effective_date,
      employeeAddress: medicationForm.values?.employeeAddress,
      dispensableDrugId: medicationForm.values?.dispensableDrugId,
    }

    if (
      medication.medication_name === medicationSearchName &&
      medication.medication_strength === prescription.Strength
    ) {
      medication.full_medication = data.full_medication
    }

    editPrescription.mutate(
      {
        prescriptionId,
        data: medication,
      },
      {
        onError: error => {
          setError(getOpheliaHttpError(error, 'Something went wrong'))
        },
        onSuccess: () => {
          if (editOnly) {
            forceClose()
            void queryClient.invalidateQueries()
            setPrescriptionBanner('Prescription successfully edited', 'success')
          } else {
            if (!prescriptionSummary.data) {
              setError('An error has occurred.')
              return
            }
            sendPrescription.mutate({
              pinCode,
              twoFacCode,
              queued: true,
              schedule: medicationForm.values?.full_medication?.Schedule || '',
              prescriptionId,
              doseSpotId: String(prescriptionSummary.data.prescription.PrescriberId),
              patientDoseSpotId: prescriptionSummary.data?.patient?.doseSpotId ?? 0,
            })
          }
        },
      },
    )
  }

  const pharmacyUpdateOnClick = () => {
    setStep('Pharmacy Edit')
  }
  const isLoading = prescriptionSummary.isFetching || getPharmacyQuery.isLoading
  const showSendingAnimation = sendPrescription.isLoading || editPrescription.isLoading

  const onBack = () => {
    if (step === 'Prescription Form') {
      return undefined
    }
    if (!hasPrescriptionBeenEdited) {
      medicationForm.setValues(data)
    }
    setStep('Prescription Form')
    setHasPrescriptionBeenEdited(true)
  }

  if (!hasPrescriptionBeenEdited && editOnly) {
    medicationForm.setValues(data)
    setHasPrescriptionBeenEdited(true)
  }

  return (
    <>
      <DrawerHeader onClose={onClose}>
        {editOnly ? 'Edit queued prescription' : 'Review & send prescription'}
      </DrawerHeader>
      <DrawerContent>
        <Stack
          sx={theme => ({
            padding: theme.other.sizes.gap.xl,
            overflowY: 'auto',
          })}
        >
          {isPharmacyInactive && <PharmacyInactiveAlert />}
          {showSendingAnimation && <Lottie animationData={animation} />}
          <LoadingOverlay visible={isLoading} />
          <MedicationFormProvider form={medicationForm}>
            {step === 'Preview' &&
              !isLoading &&
              prescriptionSummary.data &&
              !showSendingAnimation && (
                <>
                  <MPrescriptionPreview
                    employee={prescriptionSummary.data.employee}
                    onEdit={onBack}
                    patient={prescriptionSummary.data.patient}
                    prescription={
                      medicationForm.values && hasPrescriptionBeenEdited
                        ? ({
                            PrescriberAgentId:
                              prescriptionSummary.data?.prescription?.PrescriberAgentId,
                            PharmacyId: medicationForm.values.pharmacyId,
                            DisplayName: medicationForm.values
                              ? medicationForm.values?.medication?.Name
                              : prescription.DisplayName,
                            Strength: medicationForm.values.medication_strength,
                            Quantity: medicationForm.values.medication_quantity,
                            DaysSupply: Number(medicationForm.values.medication_days_supply),
                            Refills: medicationForm.values.medication_refills,
                            NoSubstitutions: !medicationForm.values.substitutionAllowed,
                            Directions: medicationForm.values.directions,
                            PharmacyNotes: medicationForm.values.notes,
                            DispenseUnitId: medicationForm.values.full_medication?.DispenseUnitId,
                            NonDoseSpotPrescriptionId: medicationForm.values.bridge && 'bridge',
                            EffectiveDate: medicationForm.values.effective_date,
                          } as DoseSpotPrescription)
                        : prescriptionSummary.data.prescription
                    }
                    followUpScheduled
                    pdmpReviewed
                  />
                  {patientAndClinicianLocations && (
                    <Stack spacing='md'>
                      <Checkbox
                        checked={employeeIsAtWorkAddress}
                        onChange={() => toggleEmployeeIsAtWorkAddress()}
                        label='I am at my work address'
                      />
                      {!employeeIsAtWorkAddress && (
                        <TextInput
                          {...medicationForm.getInputProps('employeeAddress')}
                          placeholder='Ex: 2 Main st, New York, NY 100001'
                          label={
                            <TooltipLabel
                              sx={({ other: { colors } }) => ({
                                backgroundColor: colors.background[6],
                              })}
                              label='Your address is protected and not shared with external sources including patients'
                            >
                              Your current address
                            </TooltipLabel>
                          }
                        />
                      )}
                    </Stack>
                  )}
                  {editOnly ? (
                    error && <div className='pt-8 text-error'>{error}</div>
                  ) : (
                    <MPrescriptionPINForm
                      requireTwoFac={
                        hasPrescriptionBeenEdited
                          ? isControlledSubstance(medicationForm.values.full_medication?.Schedule)
                          : isControlledSubstance(prescriptionSummary.data.prescription?.Schedule)
                      }
                      error={error}
                    />
                  )}
                </>
              )}
            {step === 'Prescription Form' && prescriptionSummary.data && !isLoading && (
              <EditQueuedPrescriptionDrawer
                pharmacy={getPharmacyQuery.data}
                pharmacyUpdateOnClick={pharmacyUpdateOnClick}
              />
            )}
            {step === 'Pharmacy Edit' && (
              <MEditPharmacyForm
                title='Update pharmacy'
                patientId={patientId}
                onSelectPharmacy={pharmacy => {
                  setTempSelectedPharmacy(pharmacy)
                }}
                shouldSetPreferredPharmacy={shouldSetPreferredPharmacy}
                setShouldSetPreferredPharmacy={setShouldSetPreferredPharmacy}
                selectedPharmacy={tempSelectedPharmacy}
              />
            )}
          </MedicationFormProvider>
        </Stack>
      </DrawerContent>
      <DrawerFooter>
        <Group position='right'>
          <PrimaryButton
            onClick={() => getSubmit()}
            rightIcon={step === 'Preview' ? <SendIcon /> : <ChevronRightIcon />}
            disabled={
              (!hasPrescriptionBeenEdited && editOnly) ||
              editPrescription.isLoading ||
              sendPrescription.isLoading
            }
          >
            {getPrescriptionButtonContent() as string}
          </PrimaryButton>
        </Group>
      </DrawerFooter>
    </>
  )
}

export const PrescriptionReviewSendDrawer = ({
  opened,
  onClose,
  ...props
}: PrescriptionSendModalProps & { opened: boolean }) => {
  // We intercept closing the drawer with a modal to confirm closing it
  const [showCloseModal, setShowCloseModal] = useState(false)
  const hideCloseModal = () => setShowCloseModal(false)
  const onCloseDrawer = (force?: boolean) => (force === true ? onClose() : setShowCloseModal(true))

  return (
    <>
      <BetterDrawer position='right' size='lg' opened={opened} onClose={onCloseDrawer}>
        <PrescriptionSendContent {...props} onClose={onCloseDrawer} />
      </BetterDrawer>
      <BetterModal opened={showCloseModal} onClose={hideCloseModal}>
        <ModalHeader onClose={hideCloseModal}>Exit and lose progress</ModalHeader>
        <ModalContent>
          <Text>If you exit now, your progress will be lost. Are you sure you want to exit?</Text>
        </ModalContent>
        <ModalFooter>
          <Group position='right'>
            <SecondaryButton onClick={hideCloseModal}>No, don&apos;t exit</SecondaryButton>
            <PrimaryButton
              onClick={() => {
                hideCloseModal()
                onClose()
              }}
            >
              Yes, exit
            </PrimaryButton>
          </Group>
        </ModalFooter>
      </BetterModal>
    </>
  )
}
