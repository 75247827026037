import { AiFillSmile } from '@react-icons/all-files/ai/AiFillSmile'
import { Employee } from '@shared/types'
import { toTime } from '@shared/utils'
import { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import useOnClickOutside from 'use-onclickoutside'
import { notificationsApi } from '../../../api'
import ALoadingSpinner from '../../../components/atoms/ALoadingSpinner'
import IconClose from '../../../components/icons/IconClose'
import MNotification from './MNotification'

const NOTIFICATIONS = 'Notifications'

type MAlertsDropdownProps = {
  setDropdown: (value: boolean) => void
  currentUser: Employee
  notificationsCount: number
  refreshAlertsCount: () => void
  readOnly?: boolean
}

const MAlertsDropdown = ({
  currentUser,
  setDropdown,
  notificationsCount,
  refreshAlertsCount,
  readOnly = false,
}: MAlertsDropdownProps) => {
  const modalRef = useRef<HTMLDivElement>(null)
  const [loading, setLoading] = useState(true)

  const notifications = useQuery(
    ['notificationsApi.getUnreadNotifications', currentUser.oid],
    () => notificationsApi.getUnreadNotifications({ employeeId: currentUser.oid }),
    { staleTime: toTime('30 sec').ms(), refetchOnWindowFocus: true },
  )

  const currentTabIsEmpty = () => {
    return notifications?.data?.length === 0
  }

  const refetchAll = async () => {
    await notifications.refetch()
    refreshAlertsCount()
  }

  useEffect(() => {
    setLoading(notifications.isFetching)
  }, [notifications.isFetching])

  useOnClickOutside(modalRef, () => setDropdown(false))

  return (
    <div className='z-110 absolute top-12 right-14 max-w-xl w-full h-full'>
      <div className='absolute top-0 right-0 z-10'>
        <button
          className='focus:outline-none rounded-full flex justify-center items-center w-6 h-6 hover:bg-gray-100 mr-6 mt-6'
          onClick={() => setDropdown(false)}
        >
          <IconClose textColor='text-indigo-600' />
        </button>
      </div>
      <div
        ref={modalRef}
        style={{ maxHeight: '70vh' }}
        className='bg-gray-50 lg:flex-shrink-0 lg:border lg:border-gray-200 rounded-xl shadow-xl w-full overflow-y-scroll'
      >
        <div className='sticky flex top-0 bg-gray-50 px-6 pt-3'>
          <div className='flex items-center'>
            <nav className='-mb-px border-b border-gray-200 flex space-x-8' aria-label='Tabs'>
              <button
                key={NOTIFICATIONS}
                className='border-indigo-500 text-indigo-600 whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm no-underline'
                aria-current='page'
              >
                {NOTIFICATIONS}
                {notificationsCount ? (
                  <span className='bg-indigo-100 text-indigo-600 hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'>
                    {notificationsCount}
                  </span>
                ) : null}
              </button>
            </nav>
          </div>
        </div>
        <div>
          <ul>
            {loading && (
              <div className='h-28'>
                <ALoadingSpinner />
              </div>
            )}
            {!loading && currentTabIsEmpty() && (
              <li className='py-4 px-6'>
                <div className='flex justify-center items-center space-x-3 focus:outline-none'>
                  <div className='h-8 w-8 bg-gray-200 rounded-full flex items-center justify-center'>
                    <AiFillSmile className='h-5 w-5 text-gray-500 rounded-full' />
                  </div>
                  <p className='text-sm font-semibold text-indigo-900'>
                    You currently have no notifications
                  </p>
                </div>
              </li>
            )}
            {!loading &&
              notifications.data?.map(notification => (
                <MNotification
                  readOnly={readOnly}
                  key={notification.notificationId}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  {...(notification as any)}
                  setNotificationsDropdown={setDropdown}
                  refetch={refetchAll}
                />
              ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default MAlertsDropdown
