import { Box, Group, Stack, Text, useMantineTheme } from '@shared/components'
import { IANAZone, dayjs, getFormattedTimeWithTimeZone } from '@shared/utils'
import isEmpty from 'lodash/isEmpty'
import { SmsStatus, getTwilioStatusText } from '../../../../../utils/twilio'
import { CommunicationsCard } from '../CommunicationsCard'
import { MessageAttachment, MessageAttachments } from './MessageAttachments'

type CommunicationsMessageProps = {
  externalId: string
  direction: 'inbound' | 'outbound'
  datetime: string | null
  status: SmsStatus
  body?: string
  attachments?: MessageAttachment[]
}

export const CommunicationsMessage = ({
  direction,
  body,
  datetime,
  status,
  attachments = [],
}: CommunicationsMessageProps) => {
  const {
    other: { colors },
  } = useMantineTheme()
  const timeZone = (dayjs.tz.guess() as IANAZone) ?? IANAZone.Eastern

  const formattedTime = datetime
    ? getFormattedTimeWithTimeZone({
        datetime,
        timeZone,
      })
    : 'Not yet delivered'

  const statusText = () => {
    return (
      status !== 'sent' && (
        <Text
          color={['failed', 'canceled'].includes(status) ? colors.error[0] : colors.success[0]}
          size='xs'
        >
          {getTwilioStatusText(status)}
        </Text>
      )
    )
  }

  /**
   * getFormattedSmsBody takes plain text with \n characters and returns
   * a block of Text components so that newlines are preserved accurately
   * when rendered by React.
   */
  const getFormattedSmsBody = (bodyWithNewlines: string) => {
    return bodyWithNewlines.split('\n').map((bodyTextBlock, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Text mih='1em' key={`${bodyTextBlock}_${index}`} color={colors.text[2]}>
        {bodyTextBlock}
      </Text>
    ))
  }

  return direction === 'outbound' ? (
    <Stack spacing='sm'>
      <Stack spacing='sm' w='100%'>
        {attachments && (
          <MessageAttachments
            attachments={attachments}
            ml='auto'
            backgroundColor={colors.background[6]}
            textColor={colors.text[2]}
          />
        )}
        {!isEmpty(body) && (
          <Box ml='xl'>
            <CommunicationsCard ml='auto' backgroundColor={colors.background[6]}>
              {getFormattedSmsBody(body as string)}
            </CommunicationsCard>
          </Box>
        )}
      </Stack>
      <Group spacing='sm' ml='auto'>
        {statusText()}
        <Text color={colors.text[1]} size='xs'>
          {formattedTime}
        </Text>
      </Group>
    </Stack>
  ) : (
    <Stack spacing='sm'>
      {attachments && (
        <MessageAttachments
          attachments={attachments}
          backgroundColor={colors.background[2]}
          textColor={colors.text[1]}
        />
      )}
      {!isEmpty(body) && (
        <CommunicationsCard mr='xl' backgroundColor={colors.background[2]}>
          <Text color={colors.text[1]}>{body}</Text>
        </CommunicationsCard>
      )}
      <Group spacing='xs'>
        <Text color={colors.text[1]} size='xs'>
          {formattedTime}
        </Text>
        {statusText()}
      </Group>
    </Stack>
  )
}
