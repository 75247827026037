import { useClipboard } from '@mantine/hooks'
import { Table, Th } from '@shared/components'
import { toTime } from '@shared/utils'
import orderBy from 'lodash/orderBy'
import { useState } from 'react'
import * as FullStory from '../../../../utils/fullstory'
import { SmartPhrase } from '../../../employees/MEmployeesSmartPhrasesTab'
import { SmartPhraseRow } from './SmartPhraseRow'

export type SmartPhraseActionVariation = 'editor' | 'copy-only'

export type SortColumn = 'name' | 'categoryName' | 'updatedAt'

type SmartPhraseTableProps = {
  smartPhrases: SmartPhrase[]
  variation: SmartPhraseActionVariation
}

export const SmartPhraseTable = ({ smartPhrases, variation }: SmartPhraseTableProps) => {
  /*
   * The useClipboard hook will help us copy the smartPhrase to the user's clipboard,
   * and the state variable will help us determine which smartPhrase has been copied.
   * The latter is important because we want to be able to show which smartPhrase has been copied.
   */
  const clipboard = useClipboard({ timeout: toTime('3 sec').ms() })
  const [copiedSmartPhraseId, setCopiedSmartPhraseId] = useState<string | undefined>()

  const onCopySmartPhrase = (smartPhrase: SmartPhrase) => {
    setTimeout(() => clipboard.copy(smartPhrase.text), 0)
    setCopiedSmartPhraseId(smartPhrase.oid)

    // Send a FullStory event
    FullStory.event('Smart Phrase Copied', {
      smartPhraseId: smartPhrase.oid,
    })
  }

  const [sortColumn, setSortColumn] = useState<SortColumn>('updatedAt')
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc')

  /*
   * If the column is already sorted, reverse the sort direction
   * Otherwise, sort by the new column in descending order
   */
  const handleSortingChange = (columnKey: SortColumn | undefined) => {
    if (!columnKey) {
      return
    }

    // If the column is already sorted, reverse the sort direction
    if (sortColumn === columnKey) {
      setSortDirection(prev => (prev === 'asc' ? 'desc' : 'asc'))

      // Otherwise, sort by the new column in descending order
    } else {
      setSortColumn(columnKey)
      setSortDirection('desc')
    }
  }

  const sortedSmartPhrases = orderBy(smartPhrases, smartPhrase => smartPhrase[sortColumn], [
    sortDirection,
  ])

  return (
    <Table withBorder withColumnBorders striped style={{ tableLayout: 'fixed' }}>
      <thead className='mantine'>
        <tr className='mantine'>
          <Th
            sortable
            sorted={sortColumn === 'name'}
            reversed={sortColumn === 'name' && sortDirection === 'asc'}
            onSort={() => handleSortingChange('name')}
            style={{ width: '20%' }}
          >
            Smart phrase
          </Th>
          <Th
            sortable
            sorted={sortColumn === 'categoryName'}
            reversed={sortColumn === 'categoryName' && sortDirection === 'asc'}
            onSort={() => handleSortingChange('categoryName')}
            /*
             * Make the width 15 characters long, which is `No category` plus some buffer.
             * Most categories will be shorter than that.
             */
            style={{ width: '15ch' }}
          >
            Category
          </Th>
          <Th
            sortable
            sorted={sortColumn === 'updatedAt'}
            reversed={sortColumn === 'updatedAt' && sortDirection === 'asc'}
            onSort={() => handleSortingChange('updatedAt')}
            // Make the width 12 characters long, which is `MM/DD/YYYY` plus some buffer
            style={{ width: '12ch' }}
          >
            Last edited
          </Th>
          <Th sortable={false}>Text</Th>
        </tr>
      </thead>
      <tbody className='mantine'>
        {sortedSmartPhrases.map(smartPhrase => {
          return (
            <SmartPhraseRow
              key={smartPhrase.oid}
              smartPhrase={smartPhrase}
              isCopied={clipboard.copied && copiedSmartPhraseId === smartPhrase.oid}
              onCopySmartPhrase={() => onCopySmartPhrase(smartPhrase)}
              variation={variation}
            />
          )
        })}
      </tbody>
    </Table>
  )
}
