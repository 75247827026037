import { Pill, PillStatus } from '@shared/components'
import { EMRTask, EMRTaskType } from '@shared/types'
import { dayjs } from '@shared/utils'

/**
 * @slaCountdownExperiment - 2024-08-09
 * Adding a countdown to the task list for Welcome Calls and Winback Calls
 * to show how much time is left to call the patient.
 */

type TaskCountdownSlaProps = {
  task: EMRTask
}

const getSla = (taskType: EMRTaskType) => {
  switch (taskType) {
    case 'call_me_now':
    case 'returning_call_me_now':
    case 'scheduled':
    case 'returning_scheduled':
      return 20
    default:
      return 1440
  }
}

const getSlaDetails = (task: EMRTask) => {
  const taskSlaMinutes = getSla(task.type)
  const scheduledDate =
    'details' in task && 'scheduledFor' in task.details && task.details.scheduledFor

  /**
   * If the task is any type of welcome call task, use the scheduled date
   * as the start time to calculate due date
   */
  const dueDate = dayjs(
    ['scheduled', 'returning_scheduled', 'call_me_now', 'returning_call_me_now'].includes(
      task.type,
    ) && scheduledDate
      ? scheduledDate
      : task.createdAt,
  ).add(taskSlaMinutes, 'minutes')

  const status: PillStatus = dayjs().isAfter(dueDate) ? 'error' : 'success'
  const countdown = dayjs().isAfter(dueDate)
    ? 'Overdue'
    : `${dayjs().to(dueDate, true)} left to call`

  return {
    status,
    countdown,
  }
}

export const TaskCountdownSla = ({ task }: TaskCountdownSlaProps) => {
  const sla = getSlaDetails(task)

  return <Pill status={sla.status}>{sla.countdown}</Pill>
}
