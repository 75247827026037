import { Loader, Select } from '@shared/components'
import { ISOString } from '@shared/types'
import { dayjs } from '@shared/utils'
import minBy from 'lodash/minBy'
import { useEffect } from 'react'
import { useLunaQuery } from '../../utils/hooks'
import { DropInClinic } from './ClinicPage'

const getLabel = ({
  clinicianFirstName,
  datetimeStart,
}: {
  clinicianFirstName: string
  datetimeStart: ISOString
}) => {
  const date = dayjs(datetimeStart).tz('America/New_York').format('MM/DD/YYYY').toUpperCase()
  const startHour = dayjs(datetimeStart).tz('America/New_York').format('h:mma')
  return `${clinicianFirstName} | ${date} | ${startHour} ET`
}

export const DropInClinicSelect = (props: {
  onClinicChange: (clinic: DropInClinic) => void
  clinic: DropInClinic | null
}) => {
  const clinicsQuery = useLunaQuery('GET /drop-in-clinics')
  const clinics = clinicsQuery.data

  useEffect(() => {
    if (!props.clinic?.oid) {
      /**
       * On initial load, select the first upcoming clinic or
       * the last clinic if there are no upcoming ones.
       * Pad by 1 hour to account for flexibility in clinic end times in practice.
       */
      const futureClinics = (clinics?.data || []).filter(clinic => {
        return dayjs(clinic.datetimeEnd).add(1, 'hour').isAfter(dayjs())
      })
      const firstUpcomingClinic = minBy(futureClinics, clinic => {
        return dayjs(clinic.datetimeEnd).valueOf()
      })
      const lastClinic = clinics?.data.at(-1)
      props.onClinicChange(firstUpcomingClinic || lastClinic!)
    }
  }, [clinics])

  const data = (clinicsQuery.data?.data ?? []).map(d => ({
    value: d.oid,
    label: getLabel(d),
  }))

  return (
    <Select
      miw='285px'
      value={props.clinic?.oid || ''}
      onChange={clinicId => {
        const nextClinic = (clinics?.data || []).find(d => d.oid === clinicId)
        props.onClinicChange(nextClinic!)
      }}
      icon={clinicsQuery.isLoading ? <Loader size='xs' /> : null}
      data={data}
      disabled={!data.length}
    />
  )
}
