import { useScrollIntoView } from '@mantine/hooks'
import {
  BetterDrawer,
  Box,
  Center,
  Checkbox,
  Divider,
  DrawerContent,
  DrawerHeader,
  Group,
  Loader,
  PrimaryButton,
  Select,
  Stack,
  Text,
  TitleThree,
} from '@shared/components'
import {
  DoseSpotPrescriptionLogEntry,
  MedicationStatus,
  PrescriptionCancelationReason,
  PrescriptionCustomStatus,
  PrescriptionStatus,
  hasGroupRole,
  readablePrescriptionStatusMap,
} from '@shared/types'
import { dayjs } from '@shared/utils'
import head from 'lodash/head'
import { useLayoutEffect, useState } from 'react'
import { useAuth } from '../../context/auth'
import { useEmrQuery } from '../../utils/hooks'
import { PrescriptionCancelModal } from './PrescriptionCancelModal'
import MPrescriptionPreview from './prescriptions/MPrescriptionPreview'
import { StatusIcon } from './prescriptions/PrescriptionRow'

type FoundEvent = { timestamp: string; status: PrescriptionCustomStatus; info?: string }

const filterEvents = (logEntry: DoseSpotPrescriptionLogEntry[]): FoundEvent[] => {
  return logEntry
    .map(entry => ({
      timestamp: entry.DateTimeStamp,
      status: (PrescriptionStatus[entry.Status] ??
        MedicationStatus[entry.MedicationStatus]) as PrescriptionCustomStatus,
      info: entry.AdditionalInfo || '',
    }))
    .filter(event => Object.keys(readablePrescriptionStatusMap).includes(event.status))
}

const prescriptionCancelationReasons: PrescriptionCancelationReason[] = [
  'Pharmacy transfer - stocking issues',
  'Pharmacy transfer - Pharmacy/pharmacist pushback',
  'Duplicate sent',
  'Resend due to change in dose or formulation',
  'Insurance issue',
  'Missing information',
  'Clinician error',
  'Other',
]

type PrescriptionDetailDrawerProps = {
  onClose: () => void
  patientId: string
  prescriptionId: number
  setPrescriptionBanner: (message: string, type: 'success' | 'warning' | 'error') => void
}

const PrescriptionDetailContent = ({
  onClose,
  patientId,
  prescriptionId,
  setPrescriptionBanner,
}: PrescriptionDetailDrawerProps) => {
  const [prescriptionMarkedAsCanceled, setPrescriptionMarkedAsCanceled] = useState<boolean>(false)
  const [displayCancelModal, setDisplayCancelModal] = useState<boolean>(false)
  const { scrollIntoView, targetRef, scrollableRef } = useScrollIntoView<HTMLDivElement>()
  const [selectedCancelationReason, setSelectedCancelationReason] =
    useState<PrescriptionCancelationReason | null>(null)

  const prescriptionSummary = useEmrQuery(
    'GET /patient/:patientId/prescription/:prescriptionId',
    {
      params: {
        patientId,
        prescriptionId: String(prescriptionId),
      },
    },
    {
      enabled: Boolean(prescriptionId),
    },
  )

  const prescriptionStatusKey = head(prescriptionSummary.data?.logs)?.Status
  const prescriptionStatus = prescriptionStatusKey ? PrescriptionStatus[prescriptionStatusKey] : ''
  const { currentUser } = useAuth()

  const userCanCancelPrescription = hasGroupRole(currentUser, 'clinician')
  const prescriptionCancelAllowed = prescriptionStatus === 'Pharmacy Verified'

  const timestamp = (str: string) => {
    /*
     * DS string is in UTC time but doesn't have a Z at the end and should. Needed to convert to browser time
     * TODO: fix this in the backend, and only pass the UTC string to the FE
     */
    const time = `${str}Z`
    return dayjs(time).format('h:mma z')
  }

  useLayoutEffect(() => {
    if (prescriptionMarkedAsCanceled) {
      scrollIntoView()
    }
  }, [prescriptionMarkedAsCanceled, scrollIntoView])

  return (
    <>
      <DrawerHeader onClose={onClose}>Prescription details</DrawerHeader>
      <DrawerContent ref={scrollableRef}>
        <Stack p='md' sx={{ overflowY: 'auto' }}>
          {prescriptionSummary.isFetching && (
            <Center>
              <Loader />
            </Center>
          )}
          {!prescriptionSummary.isFetching && prescriptionSummary.data && (
            <>
              <MPrescriptionPreview
                employee={prescriptionSummary.data.employee}
                patient={prescriptionSummary.data.patient}
                prescription={prescriptionSummary.data.prescription}
                followUpScheduled
                pdmpReviewed
              />
              <TitleThree>Prescription tracking</TitleThree>
              {filterEvents(prescriptionSummary.data.logs).map((event, index) => (
                <>
                  {index > 0 && (
                    <Divider
                      sx={theme => ({
                        size: theme.other.sizes.border.md,
                      })}
                    />
                  )}
                  <Stack
                    spacing='xs'
                    key={event.status + event.timestamp}
                    sx={theme => ({
                      paddingTop: theme.other.sizes.padding.lg,
                      paddingBottom: theme.other.sizes.padding.lg,
                    })}
                  >
                    <Group spacing='sm'>
                      <StatusIcon status={event.status} />
                      <Text bold>{readablePrescriptionStatusMap[event.status]}</Text>
                    </Group>
                    {event.info && <Text>{event.info}</Text>}
                    <Text>
                      {new Date(event.timestamp).toLocaleDateString()} at{' '}
                      {timestamp(event.timestamp)}
                    </Text>
                  </Stack>
                </>
              ))}

              {userCanCancelPrescription && (
                <Checkbox
                  checked={prescriptionMarkedAsCanceled}
                  label='Mark this prescription as canceled'
                  onChange={() => {
                    setPrescriptionMarkedAsCanceled(!prescriptionMarkedAsCanceled)
                  }}
                />
              )}

              {prescriptionMarkedAsCanceled && (
                <Stack>
                  <Select
                    id='dropdown'
                    data={prescriptionCancelationReasons}
                    placeholder='Select one...'
                    label='Why are you canceling this prescription?'
                    value={selectedCancelationReason}
                    onChange={(selection: string) =>
                      setSelectedCancelationReason(selection as PrescriptionCancelationReason)
                    }
                  />
                  <Box ref={targetRef}>
                    <PrimaryButton
                      onClick={() => setDisplayCancelModal(!displayCancelModal)}
                      disabled={!selectedCancelationReason}
                    >
                      Mark as canceled
                    </PrimaryButton>
                  </Box>
                </Stack>
              )}
            </>
          )}
        </Stack>
      </DrawerContent>
      {displayCancelModal && selectedCancelationReason && prescriptionMarkedAsCanceled && (
        <PrescriptionCancelModal
          onClose={() => {
            setDisplayCancelModal(false)
          }}
          opened={Boolean(displayCancelModal)}
          patientId={patientId}
          prescriptionId={prescriptionId}
          cancelationReason={selectedCancelationReason}
          setPrescriptionBanner={setPrescriptionBanner}
          prescriptionCancelAllowed={prescriptionCancelAllowed}
        />
      )}
    </>
  )
}

export const PrescriptionDetailDrawer = ({
  opened,
  ...props
}: PrescriptionDetailDrawerProps & { opened: boolean }) => {
  return (
    <BetterDrawer opened={opened} onClose={props.onClose} position='right' size='lg'>
      <PrescriptionDetailContent {...props} />
    </BetterDrawer>
  )
}
